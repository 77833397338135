<div class="modal-content">
    <div class="modal-header justify-content-center pb-2">
        <h2 class="modal-title">Notificações</h2>
        <button type="button" class="close" data-dismiss="modal" (click)="activeModal.close(hasChange)">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>

    <div class="ml-2 mr-1">
        <ul class="nav nav-tabs">
            <a class="nav-link" [ngClass]="activeTable == 'naolidos' ? 'bg-blue text-white' : '' " role="button"
                (click)="changeTable('naolidos')">
                Não Lidos
                <span *ngIf="activeTable == 'naolidos'" style="font-size: 0.65rem;">
                    ({{ notifications.length }})
                </span>
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'lidos' ? 'bg-blue text-white' : '' " role="button"
                (click)="changeTable('lidos')">Lidos
                <span *ngIf="activeTable == 'lidos'" style="font-size: 0.65rem;">
                    ({{ notifications.length }})
                </span>
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'config' ? 'bg-blue text-white' : '' " role="button"
                (click)="changeTable('config')">
                Opções
            </a>
            <a class="nav-link" [ngClass]="activeTable == 'email' ? 'bg-blue text-white' : '' " role="button"
                (click)="changeTable('email')">
                Email
            </a>

            <div class="select-all-options d-flex align-items-center">
                <input *ngIf="activeTable != 'config' && activeTable != 'email'" class="global-checkbox mr-1" type="checkbox"
                    [checked]="activeTable != 'config' && activeTable != 'email' &&  selectAllOptions[activeTable]"
                    ngbTooltip="Marcar/Desmarcar Todos" (change)="selectAllNotifications()" container="body">

                <span role="button" class="ml-2" *ngIf="activeTable == 'naolidos'"
                    (click)="doNotificationsAction(notifications, 'done')">
                    <i class="fa fa-envelope-open" ngbTooltip="Marcar como lido" container="body"></i>
                </span>
                <span role="button" class="ml-2" *ngIf="activeTable == 'lidos'"
                    (click)="doNotificationsAction(notifications, 'undone')">
                    <i class="fa fa-envelope" ngbTooltip="Marcar como não lido" container="body"></i>
                </span>

                <span *ngIf="activeTable != 'config' && activeTable != 'email'" class="ml-2" role="button"
                    (click)="doNotificationsAction(notifications, 'delete')">
                    <i class="fa fa-trash" ngbTooltip="Excluir" container="body"></i>
                </span>
            </div>

        </ul>
    </div>

    <div class="modal-body text-left pt-2 pb-2 pl-3 pr-3 overflow-auto">
        <div [ngStyle]="setMaxModalHeigth()">

            <!-- Notificações -->
            <div *ngIf="activeTable != 'config' && activeTable != 'email'">
                <div [hidden]="!(notifications && notifications.length == 0)" class="pt-2" style="font-size: 0.7rem;">
                    Sem notificações!
                </div>

                <div *ngFor="let notification of notifications | slice: (page-1) * pageSize : page * pageSize">
                    <div [ngStyle]="setSelectedStyle(notification)">
                        <div class="pt-1 d-flex align-items-center" style="font-size: 0.65rem;">
                            <span class="mr-2">{{ notification.date | date: 'shortDate' }}</span>
                            <input class="notify-list-checkbox" type="checkbox" [checked]="notification.select"
                                ngbTooltip="Marcar/Desmarcar" (change)="notification.select = !notification.select"
                                container="body">
                        </div>
                        <div style="font-size: 0.85rem;" [ngStyle]="titleStyle(notification)"
                            (click)="notification.select = !notification.select" role="button">
                            <span> <i [ngClass]="getMsgIcon(notification.type)"></i> </span>
                            <span> {{ notification.titulo }} </span>
                        </div>
                        <div class="mb-0" style="font-size: 0.78rem;">
                            <pre *ngIf="!notification.url">{{ notification.mensagem }}</pre>
                            <span *ngIf="notification.url">
                                <pre>{{ notification.mensagem }}<a href="{{notification.url}}" target="_blank"> Abrir.</a></pre>
                            </span>
                        </div>
                        <hr>
                    </div>
                </div>
            </div>

            <!-- Configuraçao -->
            <div *ngIf="activeTable == 'config'">
                <a class="nav-link cursor-pointer">
                    <span style="font-size: 16px;">
                        Notificações de Sistema
                    </span>
                </a>
                <div class="ml-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="alerta"
                            [(ngModel)]="userEventsLocal.alerta.delete">
                        <label class="form-check-label" for="alerta">
                            <i [ngClass]="getMsgIcon('alerta')"></i>&nbsp;
                            Alertas do Sistema
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="aviso"
                            [(ngModel)]="userEventsLocal.aviso.delete">
                        <label class="form-check-label" for="aviso">
                            <i [ngClass]="getMsgIcon('aviso')"></i>&nbsp;
                            Avisos do Sistema
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="versao"
                            [(ngModel)]="userEventsLocal.versao.delete">
                        <label class="form-check-label" for="versao">
                            <i [ngClass]="getMsgIcon('versao')"></i>&nbsp;
                            Atualizações do Sistema
                        </label>
                    </div>
                </div>

                <hr class="mt-2">
                <a class="nav-link cursor-pointer mt-1">
                    <span style="font-size: 16px;">
                        Eventos dos Ativos
                    </span>
                </a>
                <div class="ml-3" *ngIf="!hasUserEventPermission">
                    <div>
                        - Disponível a partir do plano <a href="/alpha">PREMIUM</a>.
                    </div>
                    <div>
                        - Eventos Corporativos, Subscrições, Proventos, Fatos Relevantes e Notícias.
                    </div>
                </div>
                <!-- EVENTOS DOS ATIVOS -->
                <div class="ml-3" *ngIf="hasUserEventPermission">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="eventosInfo"
                            [(ngModel)]="userEventsLocal.eventos.delete">
                        <label class="form-check-label" for="eventosInfo">
                            <i class="fas fa-calendar-check text-warning"></i>&nbsp;
                            Eventos Corporativos
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="subscricaoInfo"
                            [(ngModel)]="userEventsLocal.subscricao.delete">
                        <label class="form-check-label" for="subscricaoInfo">
                            <i class="fas fa-file-invoice text-purple"></i>&nbsp;
                            Subscrições
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="proventosInfo"
                            [(ngModel)]="userEventsLocal.proventos.delete">
                        <label class="form-check-label" for="proventosInfo">
                            <i class="fas fa-coins"></i>&nbsp;
                            Proventos
                        </label>
                    </div>
                </div>

                <div *ngIf="hasUserEventPermission">
                    <hr class="mt-2">
                    <a class="nav-link cursor-pointer mt-1 ml-0">
                        <span style="font-size: 16px;">
                            Notícias e Fatos Relevantes
                        </span>
                    </a>
                </div>
                <!-- NOTICIAS DO ATIVO -->
                <div class="ml-3" *ngIf="hasUserEventPermission">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="fatosrelevantes"
                            [(ngModel)]="userEventsLocal.fatosrelevantes.delete">
                        <label class="form-check-label" for="fatosrelevantes">
                            <i [ngClass]="getMsgIcon('fatosrelevantes')"></i>&nbsp;
                            Fatos Relevantes
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="informesrelatorios"
                            [(ngModel)]="userEventsLocal.informesrelatorios.delete">
                        <label class="form-check-label" for="informesrelatorios">
                            <i [ngClass]="getMsgIcon('informesrelatorios')"></i>&nbsp;
                            Informes e Relatórios
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="comunicadosavisos"
                            [(ngModel)]="userEventsLocal.comunicadosavisos.delete">
                        <label class="form-check-label" for="comunicadosavisos">
                            <i [ngClass]="getMsgIcon('comunicadosavisos')"></i>&nbsp;
                            Comunicados e Avisos
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="atas"
                            [(ngModel)]="userEventsLocal.atas.delete">
                        <label class="form-check-label" for="atas">
                            <i [ngClass]="getMsgIcon('atas')"></i>&nbsp;
                            Atas de Reunião
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="noticias"
                            [(ngModel)]="userEventsLocal.noticias.delete">
                        <label class="form-check-label" for="noticias">
                            <i [ngClass]="getMsgIcon('noticias')"></i>&nbsp;
                            Demais Notícias
                        </label>
                    </div>
                </div>
                <hr class="mt-2">
                <div class="small mt-2 ml-2">
                    <button class="btn btn-primary btn-sm border" (click)="saveEventNewsOptions()">
                        Salvar
                    </button>
                </div>
            </div>

            <!-- Configuraçao -->
            <div *ngIf="activeTable == 'email'">
                <a class="nav-link cursor-pointer">
                    <span style="font-size: 16px;">
                        Notificações por e-mail
                    </span>
                </a>
                <div class="ml-3">
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="plano"
                            [(ngModel)]="userEmailConfig.in_plano">
                        <label class="form-check-label" for="plano">
                            <i [ngClass]="getMsgIcon('plano')"></i>&nbsp;
                            Vencimento do plano
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="checkbox" id="promocao"
                            [(ngModel)]="userEmailConfig.in_promocao">
                        <label class="form-check-label" for="promocao">
                            <i [ngClass]="getMsgIcon('promocao')"></i>&nbsp;
                            Avisos e Promoções
                        </label>
                    </div>
                    <div *ngIf="isAlphaUser()" class="form-check">
                        <input class="form-check-input" type="checkbox" id="resumosemanal"
                            [(ngModel)]="userEmailConfig.in_resumo_semanal">
                        <label class="form-check-label" for="resumosemanal">
                            <i [ngClass]="getMsgIcon('resumosemanal')"></i>&nbsp;
                            Relatório semanal
                        </label>
                    </div>
                    <div *ngIf="isAlphaUser()" class="form-check">
                        <input class="form-check-input" type="checkbox" id="resumomensal"
                            [(ngModel)]="userEmailConfig.in_resumo_mensal">
                        <label class="form-check-label" for="resumomensal">
                            <i [ngClass]="getMsgIcon('resumomensal')"></i>&nbsp;
                            Relatório mensal
                        </label>
                    </div>
                </div>
                <hr class="mt-2">
                <div class="small mt-2 ml-2">
                    <button class="btn btn-primary btn-sm border" (click)="saveEmailConfigOptions()">
                        Salvar
                    </button>
                </div>
            </div>

        </div>
    </div>

    <div class="modal-footer pt-3">
        <div *ngIf="activeTable != 'config' && activeTable != 'email' && collectionSize > pageSize" class="mr-auto">
            <ngb-pagination [(page)]="page" [pageSize]="pageSize" [boundaryLinks]="false" [maxSize]="1" [rotate]="false"
                [collectionSize]="collectionSize" size="sm">
            </ngb-pagination>
        </div>
        <div>
            <button type="button" class="btn btn-sm btn-secondary" data-dismiss="modal"
                (click)="activeModal.close(hasChange)">
                Fechar
            </button>
        </div>
    </div>

</div>
