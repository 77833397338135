<div class="header pt-md-6" [ngClass]="isDarkMode ? 'bg-dark' : 'bg-gradient-default' ">

    <!-- <a href="/alpha">
        <div [ngClass]="bfClass">Cyber Monday 2024: até 46% de desconto nos planos, só hoje!</div>
    </a> -->

    <div [hidden]="!hideMarquee" role="button" class="text-center text-small" #t="ngbTooltip"
        ngbTooltip="Clique para mostrar" container="body" (click)="toggleMarquee(t)">
        Mostrar Carrossel de cotações
    </div>

    <div [hidden]="hideMarquee" role="button" class="marquee mb-2" #t2="ngbTooltip"
        ngbTooltip="Clique para esconder Carrossel" container="body" (click)="toggleMarquee(t2)">
        <div class="row caroussel">
            <span *ngFor="let index of marqueeIndex">
                <span *ngIf="index.ativo" class="index-title">{{ index.ativo }}</span>
                <span *ngIf="index.price" class="index-value">
                    {{ currencySymbols(index.currency) }} {{ index.price | number: dynamicCss.decimalFormat(index.price,
                    2)}}
                </span>
                <span *ngIf="index.changepct || index.changepct == 0" class="index-change"
                    [ngStyle]="dynamicCss.stylePriceColor(index.changepct , 'red', 'green')">
                    {{ index.changepct | number: '1.2-2' }}%
                </span>
            </span>
            <div class="caroussel-additional">
                <span *ngFor="let index of marqueeIndex">
                    <span *ngIf="index.ativo" class="index-title">{{ index.ativo }}</span>
                    <span *ngIf="index.price" class="index-value">
                        {{ currencySymbols(index.currency) }} {{ index.price | number:
                        dynamicCss.decimalFormat(index.price, 2)}}
                    </span>
                    <span *ngIf="index.changepct || index.changepct == 0" class="index-change"
                        [ngStyle]="dynamicCss.stylePriceColor(index.changepct , 'red', 'green')">
                        {{ index.changepct | number: '1.2-2' }}%
                    </span>
                </span>
            </div>
        </div>
    </div>

    <div class="container-fluid">
        <div class="header-body">

            <div class="info-card-header">
                <div class="row">

                    <div class="card info-card">
                        <span class="card-title text-uppercase">
                            Patrimônio
                            <span (click)="toggleVisible()" role="button">
                                <i class="far fa-eye-slash" [hidden]="visibleValues"></i>
                                <i class="far fa-eye" [hidden]="!visibleValues"></i>
                            </span>
                        </span>

                        <span class="card-value">
                            <span [hidden]="visibleValues" (click)="toggleVisible()" role="button"> - </span>
                            <span [hidden]="!visibleValues">
                                {{ infoCards.valueMarket | currency : currencyDefault }}
                                <span style="font-size: 10px;">
                                    (Atual)
                                </span>
                            </span>
                        </span>

                        <span class="mt-0 pt-0" style="font-size: x-small;" [hidden]="!visibleValues">
                            <span [hidden]="visibleValues" (click)="toggleVisible()" role="button"> - </span>
                            {{ infoCards.valueTotal | currency : currencyDefault }}
                            <span style="font-size: 10px;">
                                (Invest)
                            </span>
                        </span>

                    </div>


                    <div class="card info-card">
                        <span class="card-title text-uppercase">
                            Lucro Aberto
                            <i class="fas fa-chart-bar fa-1x text-muted"></i>
                        </span>

                        <span class="card-value">
                            <span [hidden]="!visibleValues">
                                {{ infoCards.valueProfit | currency : currencyDefault }}
                            </span>
                            <span (click)="toggleVisible()" role="button" [hidden]="visibleValues"> - </span>
                        </span>

                        <span style="font-size: x-small;"
                            [ngStyle]="dynamicCss.stylePriceColor(infoCards.valueProfit, 'red', 'blue')">
                            <i class="fa fa-arrow-up fa-1x" *ngIf='infoCards.valueProfit >= 0'></i>
                            <i class="fa fa-arrow-down fa-1x" *ngIf='!(infoCards.valueProfit >= 0)'></i>
                            {{ (infoCards.valueMarket / infoCards.valueTotal - 1) * 100 | number : '1.2-2' }}%
                        </span>
                    </div>


                    <div class="card info-card">
                        <span class="card-title text-uppercase">
                            Lucro Trades
                            <i class="fas fa-dollar-sign text-muted"></i>
                        </span>

                        <span class="card-trade">
                            <span [hidden]="visibleValues" (click)="toggleVisible()" role="button"> - </span>
                            <span [hidden]="!visibleValues"
                                [ngStyle]="dynamicCss.stylePriceColor(infoCards.operationSt, 'red', 'blue')">
                                {{ infoCards.operationSt | currency : currencyDefault }}
                                <span style="font-size: 10px;">
                                    (ST)
                                </span>
                            </span>
                        </span>

                        <span class="card-trade">
                            <span [hidden]="!visibleValues"
                                [ngStyle]="dynamicCss.stylePriceColor( infoCards.operationDt, 'red', 'blue')">
                                {{ infoCards.operationDt | currency : currencyDefault }}
                                <span style="font-size: 10px;">
                                    (DT)
                                </span>
                            </span>
                        </span>
                    </div>


                    <div class="card info-card">
                        <span class="card-title text-uppercase">
                            Proventos <i class="fas fa-coins text-muted"></i>
                        </span>

                        <span class="card-value">
                            <span [hidden]="visibleValues" (click)="toggleVisible()" role="button"> - </span>
                            <span [hidden]="!visibleValues">
                                {{ infoCards.valueEarnings | currency : currencyDefault }}
                            </span>
                        </span>

                        <span class="mt-0 pt-0" style="font-size: x-small;" [hidden]="!visibleValues">
                            {{ infoCards.avg12mEarnings | currency : currencyDefault }} (méd.12m)
                        </span>
                    </div>

                    <div class="card info-card">
                        <span class="card-title text-uppercase">
                            Lucro Total
                        </span>

                        <span class="card-value">
                            <span [hidden]="visibleValues" (click)="toggleVisible()" role="button"> - </span>
                            <span [hidden]="!visibleValues"
                                [ngStyle]="dynamicCss.stylePriceColor( infoCards.total, 'red', 'blue')">
                                {{ infoCards.total | currency : currencyDefault }}
                            </span>
                        </span>

                        <span class="mt-0 pt-0" style="font-size: x-small;" [hidden]="!visibleValues">
                        </span>
                    </div>

                </div>
            </div>
            <span class="date-update-prices">Última atualização das cotações: {{ lastUpdatedPrices }}</span>
        </div>
    </div>
</div>