/* src/app/components/notifications/notifications.component.scss */
.badge {
  position: relative;
  top: -7px;
  left: -20px;
  border: 1px solid;
  border-radius: 50%;
}
.fa-envelope {
  color: rgb(180, 180, 180);
}
/*# sourceMappingURL=notifications.component.css.map */
