import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectConfig } from '@ng-select/ng-select';
import { TourService } from 'ngx-ui-tour-ngx-bootstrap';
import { ModalTermosUsoComponent } from 'src/app/components/modal-termos-uso/modal-termos-uso.component';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss'],
    standalone: false
})
export class AdminLayoutComponent implements OnInit {

  public aceiteTermosUso: boolean = true;
  public windowWidth = window.innerWidth;

  constructor(
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _userService: UserService,
    private _tourService: TourService,
    private _ngSelectConfig: NgSelectConfig
  ) { }

  ngOnInit() {
    let userInfo = this._authService.getDecodedJwt()
    let lastSixHours = new Date().getTime() - (6 * 60 * 60 * 1000)

    if (!userInfo.date || (lastSixHours > userInfo.date)) {
      if (localStorage.getItem('walletId')) {
        this._userService.getUserWithWallet().subscribe((user) => {
          this._authService.setSession(user);
          userInfo = this._authService.getDecodedJwt()
        })
      } else {
        this._userService.getUser().subscribe((user) => {
          this._authService.setSession(user);
          userInfo = this._authService.getDecodedJwt()
        })
      }
    }
    
    this._ngSelectConfig.notFoundText = 'Termo não encontrado'
    this.aceiteTermosUso = userInfo.aceite_termos_uso
    this.initializeTour();

    if (!this.aceiteTermosUso) {
      const modalRef = this._modalService.open(ModalTermosUsoComponent, { size: 'xl', backdrop: 'static', backdropClass: 'backdropClass', keyboard: false })
      let is_mobile = this.windowWidth < 767
      if (!is_mobile && !userInfo.in_tour) {
        // ignora tela pequena (mobile) e se o usuario ainda não fez o tour
        modalRef.result.then((result) => {
          let userLocalStorage = JSON.parse(localStorage.getItem('usuario')) || {};
          console.log("Starting tour");
          this._tourService.end$.subscribe((value: void) => {
            this._userService.updateTour().subscribe({
              next: (msg) => {
                userLocalStorage.in_tour = true;
                localStorage.setItem('usuario', JSON.stringify(userLocalStorage));
              }
            })
          })
          this._tourService.start();
        })
      }
    }
  }

  createTourStep(anchorId: string, title: string, content: string, placement: "left" | "top" | "bottom" | "right" | "auto") {
    return {
      anchorId: anchorId, title: title, content: content, endBtnTitle: "Finalizar",
      enableBackdrop: true, prevBtnTitle: "Anterior", nextBtnTitle: "Próximo", placement: placement
    };
  }

  restartTour() {
    this._tourService.start();
  }

  initializeTour(): void {
    this._tourService.initialize([
      this.createTourStep("userMenu", "Bem Vindo",
        "Iremos guiá-lo pelo sistema para que conheça todas as funcionalidades. Caso queira ver este tutorial futuramente, clique aqui no menu.",
        "left"),
      this.createTourStep("Portfólio", "Portfólio",
        "Aqui você visualiza seus ativos, preço médio, quantidade, cotação atual e etc.",
        "left"),
      this.createTourStep("Dashboard", "Dashboard",
        "Aqui você tem uma visão geral dos seus ativos, rentabilidade, proventos e também pode fazer o comparativo com outros indicadores.",
        "left"),
      this.createTourStep("Operações", "Operações",
        "Este é um dos principais menus, nele você irá cadastrar todas suas operações, como por exemplo compra e venda de ativos.<br><br>Atualmente suportamos Ações de diversos países, Tesouro Direito, Fundo de Investimento, Debenture e outros, clique <a href='https://www.dlombelloplanilhas.com/faq'>aqui</a> para consultar todas opções.<br><br>Também é possível cadastrar outros tipos de ativos como Poupança ou outros investimentos.",
        "left"),
      this.createTourStep("Proventos", "Proventos",
        "Cadastre aqui seus proventos como rendimentos, dividendos, juros sobre capital próprio e outros.",
        "left"),
      this.createTourStep("Extratos", "Extratos",
        "Importe os extratos da B3 e de corretoras americanas, facilitando o cadastro de ativos e garantindo o registro das suas operações, dividendos, proventos e etc.",
        "left"),
      this.createTourStep("Alocação", "Alocação",
        "Crie carteiras de alocação, defina o peso de cada carteira e ativos, te auxiliando no controle de exposição e suas estratégias.",
        "left"),
      this.createTourStep("Darf", "DARF",
        "Tenha acesso a todas as informações necessárias para efetuar pagamentos de DARFs referentes às suas operações (exclusivo para Usuário Alpha).",
        "left"),
      this.createTourStep("Dirpf", "Declaração Imposto de Renda",
        "Este menu irá ajudá-lo a preencher sua Declaração de Imposto de Renda baseada em suas operações (exclusivo para Usuário Alpha).",
        "left"),
      this.createTourStep("Configurações", "Configurações", "Ajuste o sistema de acordo com suas preferências.", "left"),
      this.createTourStep("faqLink", "Perguntas frequentes",
        "Para finalizar, caso tenha alguma dúvida consulte nosso FAQ ou entre em contato através de um dos canais disponiveis neste item.",
        "left"),
    ]);
  }
}
