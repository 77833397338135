import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import StorageUtil from 'src/app/util/storage-util';

declare interface RouteInfo {
  path: string;
  title: string;
  icon: string;
  class: string;
}
export const ROUTES: RouteInfo[] = [
  { path: '/carteira', title: 'Portfólio', icon: 'fas fa-wallet text-red', class: '' },
  { path: '/dashboard', title: 'Dashboard', icon: 'fas fa-chart-line text-green', class: '' },
  { path: '/operacoes', title: 'Operações', icon: 'fas fa-clipboard-list text-blue', class: '' },
  { path: '/proventos', title: 'Proventos', icon: 'fas fa-coins', class: '' },
  { path: '/extratos', title: 'Extratos', icon: 'fas fa-file-import text-teal', class: '' },
  { path: '/alocacao', title: 'Alocação', icon: 'fas fa-chart-pie text-pink', class: '' },
  { path: '/radar', title: 'Radar', icon: 'fa fa-map-marker text-yellow', class: '' },
  { path: '/darf', title: 'Darf', icon: 'fas fa-file-invoice-dollar text-purple', class: '' },
  { path: '/dirpf', title: 'Dirpf', icon: 'fas fa-paw text-orange', class: '' },
  { path: '/configuration', title: 'Configurações', icon: 'ni ni-settings-gear-65', class: '' },
  { path: '/alpha', title: 'Planos', icon: '', class: '' }
];

@Component({
    selector: 'app-sidebar',
    templateUrl: './sidebar.component.html',
    styleUrls: ['./sidebar.component.scss'],
    standalone: false
})
export class SidebarComponent implements OnInit, AfterViewInit {

  public menuItems: any[];
  public isCollapsed = true;
  public userInfo: any;
  public menuOpened: any = false;
  public isDarkMode = false

  constructor(
    private _router: Router,
    private _authService: AuthService,
    private _viewOptionsService: ViewOptionsService
  ) { }

  ngOnInit() {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode)
    this.userInfo = this._authService.getBasicUserInfo();
    let nomeDoInvestidor = StorageUtil.getActiveWalletName().nome
    this.userInfo.photo = this.userInfo.photo == 'null' ? 'https://cdn4.iconfinder.com/data/icons/small-n-flat/24/user-512.png' : this.userInfo.photo
    this.userInfo.name = nomeDoInvestidor == 'Default' && this.userInfo.name != 'null' ? this.userInfo.name : nomeDoInvestidor
    this.menuItems = ROUTES.filter(menuItem => menuItem.icon != '');
    this._router.events.subscribe(() => {
      this.isCollapsed = false;
      this.toggleCollapsedMobile();
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.openMenu();
    }, 30);
  }

  toggleCollapsedMobile() {
    let menuMobile = document.getElementById('sidenav-collapse-main');
    if (menuMobile) {
      if (this.isCollapsed) { // se o menu está aberto
        menuMobile.style.display = 'block';
        this.isCollapsed = false;
      } else {
        menuMobile.style.display = 'none';
        this.isCollapsed = true;
      }
    }
  }

  toggleDarkMode() {
    this._viewOptionsService.changeDarkMode();
  }

  toggleMenu() {
    if (this.menuOpened) { // se o menu está aberto
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openMenu() {
    let navText = Array.from(document.getElementsByClassName('nav-text') as HTMLCollectionOf<HTMLElement>)

    for (let index = 0; index < navText.length; index++) {
      navText[index].style.display = 'block'
    }

    let mainContent = document.getElementsByClassName('main-content')[0];
    mainContent.classList.remove("main-content-closed");
    this.menuOpened = true;
  }

  closeMenu() {
    let navText = Array.from(document.getElementsByClassName('nav-text') as HTMLCollectionOf<HTMLElement>)
    for (let index = 0; index < navText.length; index++) {
      navText[index].style.display = 'none'
    }
    this.addClassClosedMenu();
  }

  addClassClosedMenu() {
    let mainContent = document.getElementsByClassName('main-content')[0];
    mainContent.classList.add("main-content-closed");
    this.menuOpened = false;
  }

  getInvestidorName() {
    let name = StorageUtil.getActiveWalletName().nome
    if (name == "Default") {
      return null
    }
    return name
  }
}
