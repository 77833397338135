/* src/app/components/modal-fundamentos/ativo-info/ativo-info.component.scss */
th {
  font-size: 0.55rem;
  padding: 0.2rem;
}
td {
  font-size: 0.6rem;
  padding: 0.2rem;
  min-width: 60px;
}
/*# sourceMappingURL=ativo-info.component.css.map */
