/* src/app/components/navbar/navbar.component.scss */
.image-profile {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 1px #ffffff;
  box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
}
.investidor-name {
  font-size: small;
}
/*# sourceMappingURL=navbar.component.css.map */
