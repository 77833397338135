/* src/app/components/header-info/header-info.component.scss */
.info-card {
  text-align: center;
  width: 10rem;
  height: 3.7rem;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
  padding-bottom: 0.3rem;
}
.card-title {
  font-weight: 600;
}
.card-value {
  font-size: 14px;
  color: rgb(65, 65, 65);
  font-weight: 600;
}
.header {
  padding-top: 1rem;
  padding-bottom: 6rem;
}
.card-trade {
  font-size: 12px;
  font-weight: 500;
}
.marquee {
  background: #000;
  text-transform: uppercase;
  white-space: nowrap;
  overflow: hidden;
  padding: 4px 0;
}
.marquee .caroussel {
  display: inline-block;
  animation: animate 55s linear infinite;
}
.marquee .caroussel:hover {
  animation-play-state: paused;
}
.marquee .caroussel-additional {
  display: inline-block;
  position: absolute;
}
.marquee span {
  padding: 0 3px;
}
@keyframes animate {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.marquee .index-title {
  color: azure;
  font-weight: 600;
  font-size: 14px;
}
.marquee .index-value {
  color: rgb(200, 200, 200);
  font-weight: 600;
  font-size: 12px;
}
.marquee .index-change {
  font-weight: 600;
  font-size: 12px;
  border-right: 1px solid rgb(200, 200, 200);
}
.date-update-prices {
  font-size: 12px;
  color: rgb(153, 153, 153);
}
/*# sourceMappingURL=header-info.component.css.map */
