<div class="pt-2 text-center">
    <div>
        <span style="font-size: 0.8rem;" class="font-weight-bolder">
            Variação no período
        </span>
    </div>
    <div>
        <table class="tb-border table-hover text-center">
            <tr>
                <th style="width: 60px;">Atual</th>
                <th style="width: 60px;">Variação</th>
                <th style="width: 60px;">(%)</th>
            </tr>
            <tr>
                <td>{{ price | number: '1.2-2'}}</td>
                <td [ngStyle]="dynamicCss.stylePriceColor(change, 'red', 'blue')">{{ change | number: '1.2-2' }}</td>
                <td [ngStyle]="dynamicCss.stylePriceColor(changepct, 'red', 'blue')">{{ changepct | number: '1.2-2' }}%
                </td>
            </tr>
        </table>
    </div>
</div>

<div id="ativo-historical-chart"></div>
<div class="pl-2">
    <span class="font-weight-bold"> Período: </span>
    <span role="button" (click)="getPeriodHistorical(1)"
        [ngClass]="periodoSelecionado == 1 ? 'font-weight-bold text-blue' : '' ">1M</span>
    &nbsp;
    <span class="" role="button" (click)="getPeriodHistorical(6)"
        [ngClass]="periodoSelecionado == 6 ? 'font-weight-bold text-blue' : '' ">6M</span>
    &nbsp;
    <span class="" role="button" (click)="getPeriodHistorical(12)"
        [ngClass]="periodoSelecionado == 12 ? 'font-weight-bold text-blue' : '' ">1A</span>
    &nbsp;
    <span class="" role="button" (click)="getPeriodHistorical(36)"
        [ngClass]="periodoSelecionado == 36 ? 'font-weight-bold text-blue' : '' ">3A</span>
    &nbsp;
    <span class="" role="button" (click)="getPeriodHistorical(60)"
        [ngClass]="periodoSelecionado == 60 ? 'font-weight-bold text-blue' : '' ">5A</span>

    &nbsp;
    <span style="font-size: 0.65rem;" class="" role="button" (click)="toggleCotacaoAjustado()"
        [ngClass]="cotacaoAjustada ? 'font-weight-bold text-blue' : '' ">
        AJUSTE
        <span class="cursor-pointer"
            ngbTooltip="Mostra o histórico com ou sem ajustes de splits no período">
            <i class="fas fa-question-circle"></i>
        </span>
    </span>
</div>