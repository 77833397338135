import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AlocacaoService } from 'src/app/services/alocacao.service';
import { AuthService } from 'src/app/services/auth.service';
import { DynamicStyleService } from 'src/app/services/dynamic-style.service';
import { ModalConfirmationDialogComponent, modalConfirmationDialogOptions } from 'src/app/components/modal-confirmation-dialog/modal-confirmation-dialog.component';
import FilterUtil from 'src/app/util/filter-util';
import StaticParams from 'src/app/util/static-params';
import AssetUtil from 'src/app/util/asset.util';
import { ModalAlocacaoComponent, modalAlocacaoOptions } from '../../modal-alocacao/modal-alocacao.component';
import { PermissionEnum } from 'src/app/enums/permission-enum';
import { CacheService } from 'src/app/services/cache.service';

@Component({
    selector: 'app-ativo-info',
    templateUrl: './ativo-info.component.html',
    styleUrls: ['./ativo-info.component.scss'],
    standalone: false
})
export class AtivoInfoComponent implements OnInit {

  @Input() info: any = {}
  @Input() portfolio: any = {}
  @Input() fundamentos: any = {}
  @Input() stockinfo: any = {}

  public helper: any = StaticParams.helper

  public currencyDefault = 'BRL'
  public alocacaoWallets = []
  public activeWallets = {}
  public hasUserAlocationPermission: boolean
  private assetAlocation: string

  constructor(
    public dynamicCss: DynamicStyleService,
    private _authService: AuthService,
    private _modalService: NgbModal,
    private _alocacaoService: AlocacaoService,
    private _cacheService: CacheService,
  ) { }

  ngOnInit(): void {
    this.hasUserAlocationPermission = this._authService.hasUserPermission(PermissionEnum.ALOCACAO)
    this.currencyDefault = this._authService.getMoedaBase() || 'BRL'
    this.calcPortifolioValues()
    this.loadAlocacalWalltes()
  }

  loadAlocacalWalltes() {
    let alocacao = this._cacheService.getCache('alocacaoCache') || {}
    let portfolio = this.portfolio

    this.portfolio.alocacao = FilterUtil.lodash().filter(alocacao?.ativos || [{}], (o) => { return (o.ativo == portfolio.ativo && o.preco_teto) })

    this.alocacaoWallets = alocacao.carteiras

    this.assetAlocation = this.portfolio.ativo
    if (this.portfolio.ativo.endsWith("_E")) {
      this.assetAlocation = AssetUtil.getOriginalAssetFromForeignBuy(this.portfolio.ativo)
    }

    for (let i in this.alocacaoWallets) {
      this.activeWallets[this.alocacaoWallets[i]] = alocacao?.ativos_obj[this.assetAlocation] ?
        alocacao?.ativos_obj[this.assetAlocation]?.indexOf(this.alocacaoWallets[i]) >= 0 : false
    }
  }

  calcPortifolioValues() {
    if (!this.portfolio.pm) {
      let portfolio = this.findParamInPortfolio(this.portfolio.ativo)
      this.portfolio.qtd = portfolio.qtd
      this.portfolio.pm = portfolio.pm
      this.portfolio.carteira = portfolio.carteira
    }
  }

  findParamInPortfolio(ativo) {
    const portfolioObject = this._cacheService.getCache('walletObject') || []
    let qtd = 0
    let vlr_investido = 0

    let carteira = []

    for (let i in portfolioObject) {
      if (ativo == portfolioObject[i].ativo || ativo == portfolioObject[i].market_cod) {
        qtd += portfolioObject[i].qtd
        vlr_investido += portfolioObject[i].vlr_investido
        carteira = portfolioObject[i].carteira ? carteira.concat(portfolioObject[i].carteira) : carteira
      }
    }

    return { qtd, pm: (vlr_investido / qtd) || 0, carteira }
  }

  currencySymbols(currency) {
    return StaticParams.currencySymbols[currency]
  }

  corretorasText(corretora) {
    return corretora ? corretora.join(', ') : '-'
  }

  tratarRazaoSocial(ativo_alias) {
    ativo_alias = ativo_alias || ''
    ativo_alias = ativo_alias.toUpperCase()
    ativo_alias = ativo_alias.split('Á').join('A').split('Â').join('A').split('Ã').join('A')
    ativo_alias = ativo_alias.split('É').join('E').split('Ê').join('E')
    ativo_alias = ativo_alias.split('Í').join('I').split('Î').join('I')
    ativo_alias = ativo_alias.split('Ó').join('O').split('Ô').join('O').split('Õ').join('O')
    ativo_alias = ativo_alias.split('Ç').join('C')
      .split('FUNDOS').join('FUNDO')
      .split('FUNDO DE INVESTIMENTO').join('FI')
      .split('FI DE ACOES').join('FIA')
      .split('FI EM ACOES').join('FIA')
      .split('FI EM COTAS').join('FIC')
      .split('FI MULTIMERCADO').join('FIM')

      .split('PREVIDENCIARIO').join('PREV').split('PREVIDENCIA').join('PREV')
      .split('CREDITO').join('CRED')

      .split('PRIVADO').join('PRIV')
      .split('EXTERIOR').join('EXT')
      .split('INSTITUCIONAL').join('INST')

      .split('RENDA FIXA').join('RF')
      .split('LONGO PRAZO').join('LP')
      .split('CURTO PRAZO').join('CP')

      .split('INVESTIMENTO').join('INVST')

    return ativo_alias
  }

  onChangeAlocacao(walletName) {
    let alocacaoWallet = this.getAlocacaoWalletAndId(walletName)

    //add ativo na lista da carteira de alocacao
    if (this.activeWallets[walletName]) {
      let arr = alocacaoWallet.wallet.ativos.filter((item) => { item.ativo == this.assetAlocation })
      if (!arr.length) {
        alocacaoWallet.wallet.ativos.push({ ativo: this.assetAlocation })
      }
      this.openModalEditAlocacaoAtivos(alocacaoWallet.wallet, walletName)
    } else {
      this.openModalEditAlocacaoAtivos(alocacaoWallet.wallet, walletName)
    }
  }

  getAlocacaoWalletAndId(walletName) {
    let wallet: any = {}
    let id: any = {}

    let alocacaoLocalCache = this._cacheService.getCache('alocacaoCache') || {}
    let walletsIds = Object.keys(alocacaoLocalCache.wallets)

    for (let i in walletsIds) {
      if (alocacaoLocalCache?.wallets[walletsIds[i]]?.nome_carteira == walletName) {
        wallet = alocacaoLocalCache?.wallets[walletsIds[i]]
        id = alocacaoLocalCache?.wallets[walletsIds[i]]?.id
        return { id, wallet }
      }
    }
    return { id, wallet }
  }

  openModalEditAlocacaoAtivos(alocacaoWallet, walletName) {
    const modalRef = this._modalService.open(ModalAlocacaoComponent, modalAlocacaoOptions);
    modalRef.componentInstance.alocacaoWallet = JSON.parse(JSON.stringify(alocacaoWallet))
    modalRef.result.then((result) => {
      if (result) {
        this._alocacaoService.setAlocacaoWalletCache(result)
        this._alocacaoService.addParametroCarteiraNoObjeto(this._cacheService.getCache('walletObject'), 'walletObject')
        this.loadAlocacalWalltes()
        this.portfolio.updated = true
      } else {
        this.activeWallets[walletName] = !this.activeWallets[walletName]
      }
    })
  }

  openModalHelperDialog(message, title) {
    const modalRef = this._modalService.open(ModalConfirmationDialogComponent, modalConfirmationDialogOptions);
    modalRef.componentInstance.modalProps = {
      type: 'confirm',
      title: title,
      message: message,
      okText: 'Fechar',
      cancelText: ''
    }
  }
}
