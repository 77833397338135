/* src/app/components/modal-fundamentos/ativo-noticias/ativo-noticias.component.scss */
td {
  font-size: 10px;
  padding: 0.2rem;
}
th {
  font-size: 10px;
  padding: 0.2rem;
}
/*# sourceMappingURL=ativo-noticias.component.css.map */
