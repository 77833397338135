import { Component, Inject, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/services/user.service';
import StaticParams from 'src/app/util/static-params';
import { DOCUMENT } from "@angular/common";
import { NgxSpinnerService } from 'ngx-spinner';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import { CacheService } from 'src/app/services/cache.service';
import { AuthService } from 'src/app/services/auth.service';
import { WalletService } from 'src/app/services/wallet.service';

export const modalSettingsOptions: NgbModalOptions = { backdrop: 'static', windowClass: 'modal-settings-dialog' };

@Component({
  selector: 'app-modal-settings',
  templateUrl: './modal-settings.component.html',
  styleUrls: ['./modal-settings.component.scss'],
})
export class ModalSettingsComponent implements OnInit {

  public isDarkMode = false
  public userSetting: any = {};
  public currencys: any = Object.keys(StaticParams.currencySymbols)

  constructor(
    private _viewOptionsService: ViewOptionsService,
    private _userService: UserService,
    private _toastr: ToastrService,
    public activeModal: NgbActiveModal,
    private spinnerService: NgxSpinnerService,
    private _cacheService: CacheService,
    private _authService: AuthService,
    private _walletService: WalletService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit(): void {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);

    if (!this.userSetting.moeda_base) {
      this.spinnerService.show()
      this._userService.getSettingsUserAPI().subscribe({
        next: (res) => {
          this.userSetting = res
          this.spinnerService.hide()
        },
        error: (err) => {
          this.spinnerService.hide()
          this._toastr.error('Erro ao buscar configurações')
        }
      })
    }
  }

  updateSettings() {
    this.spinnerService.show()
    this._userService.updateSettingsUserAPI(this.userSetting).subscribe({
      next: (updatedUser) => {
        this.successConfigUpdated(updatedUser)
      },
      error: (err) => {
        if (err.name == "TimeoutError" || err.code == 524 || err.code == 0) {
          this._walletService.isFullPortfolioComplete().subscribe({
            next: (res) => {
              this._userService.getUser().subscribe({
                next: (user) => {
                  this.successConfigUpdated(user)
                },
                error: (err) => { }
              })
            },
            error: (err) => {
              this.errorConfigUpdated()
            }
          })
        } else {
          this.errorConfigUpdated()
        }
      }
    })
  }

  private successConfigUpdated(user) {
    this._cacheService.clearCacheOnOperationsChange()
    this._authService.setSession(user)
    if (this.userSetting.reload == false) {
      this._toastr.success('Configurações atualizadas!');
      this.activeModal.close(1);
      this.spinnerService.hide()
    } else {
      setTimeout(() => {
        this.document.location.reload()
        this.document.location.href = '/carteira'
      }, 2000);
      this._toastr.success('Configurações atualizadas. Aguarde enquanto recarregamos a página!');
    }
  }

  private errorConfigUpdated() {
    this.spinnerService.hide()
    this._toastr.error('Erro ao atualizar as configurações!')
  }

  resetDefault() {
    let reload = this.userSetting?.reload == false
    this.userSetting = {
      "moeda_base": "BRL",
      "calc_por_corretora": true,
      "dia_liq_ptax": 0,
      "usar_saldo_vd20k": true,
      "dt_vd20k": true,
      "outros_vd20k": true,
      "opcoes_vd20k": true,
      "vsub_FII": false,
      "vsub_isento": false,
      "bdr_like_acao": false,
      "darf_inicio": 2020,
      "tributavel_normal": 0,
      "tributavel_daytrade": 0,
      "tributavel_fii": 0,
      "darf_total": 0
    }
    if (reload) {
      this.userSetting.reload = false
    }
  }
}
