<nav class="navbar navbar-top navbar-expand-md navbar-dark" id="navbar-main">
  <div class="container-fluid">
    <!-- Brand -->
    <a class="h4 mb-0 text-white text-uppercase d-none d-md-flex d-lg-inline-block d-xl-inline-block">{{ getTitle() }}</a>
    <!-- User -->
    <ul class="navbar-nav align-items-center d-none d-md-flex d-lg-inline-block d-xl-inline-block">
      <li class="nav-item" ngbDropdown placement="bottom-right">

        <div class="media align-items-center" tourAnchor="userMenu">
          <app-notifications [autoOpenModal]="autoOpenModal"></app-notifications>

          <a class="nav-link pr-0" role="button" ngbDropdownToggle>
            <span class="avatar avatar-sm rounded-circle">
              <img alt="Image placeholder" src="{{ userInfo?.photo }}" class="image-profile">
            </span>
          </a>

          <div class="ml-2 text-white cursor-pointer">
            <a class="" role="button" ngbDropdownToggle>
              <div class="text-white font-weight-bold">Investidor</div>
              <div class="text-gray investidor-name  font-italic">{{ userInfo?.name }}</div>
            </a>
          </div>
        </div>

        <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
          <app-navbar-user></app-navbar-user>
        </div>
      </li>
    </ul>
  </div>
</nav>