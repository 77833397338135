// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .modal-alocacao .modal-dialog {
  max-width: 390px;
  font-size: 12px;
}

input {
  width: 140px;
}

.edit-row {
  font-size: 12px;
  font-weight: 700;
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-alocacao/modal-alocacao.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,kBAAA;AACJ","sourcesContent":["::ng-deep .modal-alocacao .modal-dialog {\n    max-width: 390px;\n    font-size: 12px;\n}\n\ninput {\n    width: 140px;\n}\n\n.edit-row {\n    font-size: 12px;\n    font-weight: 700;\n    text-align: center;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
