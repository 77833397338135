// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#termos-uso-text-div {
  max-height: 600px;
  overflow: auto;
}

p {
  font-weight: 500;
}

.btn {
  width: 120px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-termos-uso/modal-termos-uso.component.scss"],"names":[],"mappings":"AAAA;EACI,iBAAA;EACA,cAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,YAAA;AACJ","sourcesContent":["#termos-uso-text-div {\n    max-height: 600px;\n    overflow: auto;\n}\n\np {\n    font-weight: 500;\n}\n\n.btn {\n    width: 120px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
