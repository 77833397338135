// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  text-align: center;
}

.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

input {
  text-align: center;
}

h2 {
  color: #31bdbd;
}

.custom-toggle input:checked + .custom-toggle-slider {
  border: 1px solid #ffffff;
}

.custom-toggle input:checked + .custom-toggle-slider:before {
  background: #ffffff;
}

::ng-deep .modal-confirmation-dialog .modal-dialog {
  max-width: 35rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-confirmation-dialog/modal-confirmation-dialog.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,kBAAA;AACJ;;AAEA;EACI,cAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":["ul {\n    text-align: center;\n}\n\n.modal-title {\n    font-size: 1rem;\n    font-weight: 600;\n    color: #fff;\n}\n\ninput {\n    text-align: center;\n}\n\nh2 {\n    color: #31bdbd;\n}\n\n.custom-toggle input:checked + .custom-toggle-slider {\n    border: 1px solid #ffffff;\n}\n\n.custom-toggle input:checked + .custom-toggle-slider:before {\n    background: #ffffff;\n}\n\n::ng-deep .modal-confirmation-dialog .modal-dialog {\n    max-width: 35rem;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
