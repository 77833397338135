// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ul {
  text-align: center;
}

.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

.image-profile {
  width: 60px;
  height: 60px;
  border-radius: 100px 100px 100px 100px;
  -moz-border-radius: 100px 100px 100px 100px;
  -webkit-border-radius: 100px 100px 100px 100px;
  /* border: 18px solid #ffffff; */
  box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);
}

.vincular-alpha {
  margin-top: 20px;
}
.vincular-alpha .vincular-alpha-form {
  margin-top: 15px;
}
.vincular-alpha .vincular-alpha-form > div {
  margin-bottom: 7px;
}
.vincular-alpha .vincular-alpha-form > button {
  margin-left: 6px;
  padding: 7px;
}
.vincular-alpha #remover-pagamento {
  margin-bottom: 10px;
}

.text-small {
  font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-profile/modal-profile.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,YAAA;EACA,sCAAA;EACA,2CAAA;EACA,8CAAA;EACA,gCAAA;EACA,uDAAA;EACA,+DAAA;EACA,4DAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;AACI;EACI,gBAAA;AACR;AACQ;EACI,kBAAA;AACZ;AAEQ;EACI,gBAAA;EACA,YAAA;AAAZ;AAII;EACI,mBAAA;AAFR;;AAMA;EACI,gBAAA;AAHJ","sourcesContent":["ul {\n    text-align: center;\n}\n\n.modal-title {\n    font-size: 1rem;\n    font-weight: 600;\n    color: #fff;\n}\n\n.image-profile {\n    width: 60px;\n    height: 60px;\n    border-radius: 100px 100px 100px 100px;\n    -moz-border-radius: 100px 100px 100px 100px;\n    -webkit-border-radius: 100px 100px 100px 100px;\n    /* border: 18px solid #ffffff; */\n    box-shadow: -1px 2px 24px 9px rgba(255, 255, 255, 0.57);\n    -webkit-box-shadow: -1px 2px 24px 9px rgba(255,255,255,0.57);\n    -moz-box-shadow: -1px 2px 24px 9px rgba(255,255,255,0.57);\n}\n\n.vincular-alpha {\n    margin-top: 20px;\n    \n    .vincular-alpha-form {\n        margin-top: 15px;\n\n        > div {\n            margin-bottom: 7px;\n        }\n\n        > button {\n            margin-left: 6px;\n            padding: 7px;\n        }\n    }\n\n    #remover-pagamento {\n        margin-bottom: 10px;\n    }\n}\n\n.text-small {\n    font-size: small;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
