<div class="row pt-2 font-weight-bold">NOTÍCIAS E FATOS RELEVANTES</div>
<div *ngIf="!hasUserNewsPermission">
    <div class="pt-1 text-center">
        Recurso disponível nos planos <span role="button" class="text-blue text-decoration-underline"
            (click)="goToAlphaPage()"><u>Premium</u></span> ou superior!
    </div>
</div>
<div *ngIf="news.length == 0 && hasUserNewsPermission">
    <div class="pt-0 text-center">
        Sem notícias ou fatos relevantes recentes!
    </div>
</div>
<div [hidden]="news.length == 0 || !hasUserNewsPermission">
    <div class="pt-1">
        <table class="tb-border text-center">
            <tr>
                <th>Data</th>
                <th>Descrição</th>
            </tr>
            <tr *ngFor="let new of news | slice: (newsPage-1) * newsPageSize : newsPage * newsPageSize">
                <td>{{ new.date }}</td>
                <td>
                    <span class="pr-1 pl-1">
                        <a [href]="new.url" target="_blank"><u> {{ new.title }} </u> </a>
                    </span>
                </td>
            </tr>
        </table>
    </div>
    <div class="mt-2">
        <ngb-pagination [(page)]="newsPage" [pageSize]="newsPageSize" [boundaryLinks]="false" [maxSize]="3"
            [rotate]="false" [collectionSize]="newsCollectionSize" size="sm">
        </ngb-pagination>
    </div>
</div>

<div class="row pt-3 font-weight-bold">EVENTOS CORPORATIVOS</div>
<div *ngIf="events.length == 0">
    <div class="pt-0 text-center">
        Sem eventos corporativos recentes!
    </div>
</div>
<div [hidden]="events.length == 0">
    <div class="pt-1">
        <table class="tb-border text-center">
            <tr>
                <th>Evento</th>
                <th>Aprovação</th>
                <th>Data Ex</th>
                <th>Fator %</th>
            </tr>
            <tr *ngFor="let row of events; let i = index">
                <td>{{ row.label }}</td>
                <td>{{ row.approvedOn || '-' }}</td>
                <td>{{ row.lastDatePrior || '-' }}</td>
                <td>{{ convertToUsNumber(row.factor) | number : '1.4-4' }}</td>
            </tr>
        </table>
    </div>
</div>

<div class="row pt-3 font-weight-bold">PROVENTOS</div>
<div *ngIf="proventos.length == 0">
    <div class="pt-0 text-center">
        Sem proventos recentes!
    </div>
</div>
<div [hidden]="proventos.length == 0">
    <div class="pt-1">
        <table class="tb-border text-center">
            <tr>
                <th>Evento</th>
                <th>Data Ex</th>
                <th>Pagamento</th>
                <th>Valor/Un</th>
            </tr>
            <tr *ngFor="let row of proventos; let i = index">
                <td>{{ row.label }}</td>
                <td>{{ row.lastDatePrior || '-' }}</td>
                <td>{{ row.paymentDate || '-' }}</td>
                <td>{{ currencySymbols[row.currency] || 'R$' }} {{ convertToUsNumber(row.rate) | number : '1.4-4' }}
                </td>
            </tr>
        </table>
    </div>
</div>