// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  font-size: 10px;
  padding: 0.2rem;
}

th {
  font-size: 10px;
  padding: 0.2rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-fundamentos/ativo-noticias/ativo-noticias.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,eAAA;AACJ;;AAEA;EACI,eAAA;EACA,eAAA;AACJ","sourcesContent":["td {\n    font-size: 10px;\n    padding: 0.2rem;\n}\n\nth {\n    font-size: 10px;\n    padding: 0.2rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
