// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .modal-earnings .modal-dialog {
  max-width: 960px;
  font-size: 12px;
}

.text-small {
  font-size: 9px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-earnings/modal-earnings.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,cAAA;AACJ","sourcesContent":["::ng-deep .modal-earnings .modal-dialog {\n    max-width: 960px;\n    font-size: 12px;\n}\n\n.text-small {\n    font-size: 9px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
