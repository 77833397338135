// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .modal-pix .modal-dialog {
  max-width: 40rem;
  font-size: 12px;
}

#qr-code {
  overflow-wrap: break-word;
}

.qr-code-base64 {
  width: 10rem;
}

.btn-link {
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-pix/modal-pix.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,eAAA;AACJ","sourcesContent":["::ng-deep .modal-pix .modal-dialog {\n    max-width: 40rem;\n    font-size: 12px;\n}\n\n#qr-code {\n    overflow-wrap: break-word;\n}\n\n.qr-code-base64 {\n    width: 10rem;\n}\n\n.btn-link {\n    cursor: pointer;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
