<div class="row pt-1 font-weight-bold">POSIÇÃO NO ATIVO</div>
<div class="pt-1">
    <table class="tb-border text-center">
        <tr>
            <th>QTD</th>
            <th>P.MÉDIO</th>
            <th>COTAÇÃO</th>
        <tr>
            <td class="text-center">
                {{ portfolio.qtd || 0 }}
            </td>
            <td class="text-center">
                {{ currencySymbols(currencyDefault) }} {{ portfolio.pm || 0 | number : '1.2-2' }}
            </td>
            <td>
                <span style="font-weight: 700;">
                    {{
                    currencySymbols(this.stockinfo?.currency || "BRL") }}
                    {{ stockinfo?.last?.price || 0 | number :
                    '1.2-2'
                    }}
                </span>
                <span style="font-size: 0.65rem;"
                    [ngStyle]="dynamicCss.stylePriceColor(portfolio.change, 'red', 'blue')">
                    {{ this.stockinfo?.last?.change || 0 | number : '1.2-2' }}
                    ({{ this.stockinfo?.last?.changepct || 0 | number : '1.2-2' }}%)
                </span>
            </td>
        </tr>
    </table>
</div>

<div *ngIf="hasUserAlocationPermission && alocacaoWallets.length">
    <div class="row pt-3 font-weight-bold">ALOCAÇÃO</div>
    <div class="pt-1">
        <table class="tb-border text-center">
            <tr>
                <th>CARTEIRAS</th>
            </tr>
            <tr>
                <td>
                    <div class="row ml-0 mr-0">
                        <div class="form-check" *ngFor="let wallet of alocacaoWallets">
                            <span class="pr-3">
                                <input class="form-check-input" type="checkbox" id="{{wallet}}"
                                    [(ngModel)]="activeWallets[wallet]" (change)="onChangeAlocacao(wallet)">
                                <label class="form-check-label" for="{{wallet}}">
                                    <span>
                                        {{wallet}}
                                    </span>
                                </label>
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
        </table>

        <table *ngIf="portfolio.alocacao.length" class="tb-border text-center">
            <tr>
                <th>CARTEIRA | PREÇO TETO | PESO ATIVO</th>
            </tr>
            <tr>
                <td>
                    <div *ngFor="let row of portfolio.alocacao;  let i = index">
                        {{ row.carteira }} | {{
                        currencySymbols(this.stockinfo?.currency || "BRL") }} {{ row.preco_teto || 0 | number : '1.2-2'
                        }} | {{ (row.peso_ativo || 0 ) * 100 | number : '1.2-2' }} %
                    </div>
                </td>
            </tr>
        </table>
    </div>
</div>

<div class="row pt-3 font-weight-bold">DADOS CADASTRAIS</div>
<div class="pt-1">
    <table class="tb-border text-center">
        <tr>
            <th>RAZÃO SOCIAL</th>
            <th>CNPJ</th>
        </tr>
        <tr>
            <td>{{ tratarRazaoSocial(info.razao_social) || '---' }}</td>
            <td>{{ info.cnpj || '---' }}</td>
        </tr>
    </table>
</div>

<div class="pt-2">
    <table class="tb-border text-center">
        <tr>
            <th>CÓD. ATIVO</th>
            <td>{{ info.market_cod || '---' }}</td>

            <th>CLASSE</th>
            <td>{{ portfolio.classe || '---' }}</td>
        </tr>
        <tr>
            <th>NOME PREGÃO</th>
            <td>{{ info.nome_pregao || '---' }}</td>

            <th>SETOR</th>
            <td>{{ portfolio.setor || '---' }}</td>
        </tr>
        <tr>
            <th>ISIN</th>
            <td>{{ info.isin || '---' }}</td>

            <th>SUBSETOR</th>
            <td>{{ portfolio.subsetor || '---' }}</td>
        </tr>
    </table>
</div>

<div class="row pt-3 font-weight-bold">FUNDAMENTOS</div>
<div [hidden]="portfolio.classe == 'FII'" class="pt-1">
    <table class="tb-border text-center">
        <tr>
            <th>PATR.LÍQ.
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.PATRLIQ.message, 'O que é o Patrimônio Líquido ?')">
                <i class="fas fa-question-circle"></i>
                </span>
            </th>  
            <td>{{ fundamentos["Patrim Líq"] || 0 | number : '1.2-2' }}</td>

            <th>D.YIELD
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.DY.message, 'O que é Dividend Yield ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Div Yield"] || '---' }}</td>
        </tr>
        <tr>
            <th>P/LPA
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.PLPA.message, 'O que é P/LPA ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["P/L"] || 0 | number : '1.2-2' }}</td>

            <th>ROE
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.ROE.message, 'O que é o ROE ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["ROE"] || '---' }}</td>
        </tr>
        <tr>
            <th>P/VPA
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.PVPA.message, 'O que é o P/VPA ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["P/VP"] || 0 | number : '1.2-2' }}</td>

            <th>MRG.LÍQ.
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.MRGLIQ.message, 'O que é Margem Líquida ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Mrg Líq."] || '---' }}</td>
        </tr>
        <tr>
            <th>DIV.BRUTA/PATR.
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.DIVBRUTPATR.message, 'O que é Div.Bruta/Patr. ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Dív Brut/Patrim"] || 0 | number : '1.2-2' }}</td>

            <th>MRG.EBIT
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.MRGEBIT.message, 'O que é Margem EBITDA ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Mrg Ebit"] || '---' }}</td>
        </tr>
    </table>
</div>

<div [hidden]="portfolio.classe != 'FII'" class="pt-1">
    <table class="tb-border text-center">
        <tr>
            <th>PATR.LÍQ.
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.PATRLIQ.message, 'O que é o Patrimônio Líquido ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Patrimônio Líq"] || 0 | number : '1.2-2' }}</td>

            <th>RENDIMENTO
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.REND.message, 'O que é Rendimento ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Dividendo"] || '---' }}</td>
        </tr>
        <tr>
            <th>VPA
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.VPA.message, 'O que é o VPA ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["VPA"] || 0 | number : '1.2-2' }}</td>

            <th>D.YIELD
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.DY.message, 'O que é o Dividend Yield ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Div Yield"] || '---' }}</td>
        </tr>
        <tr>
            <th>P/VPA
                <span class="cursor-pointer"(click)="openModalHelperDialog(helper.PVPA.message, 'O que é o P/VPA ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["P/VPA"] || 0 | number : '1.2-2' }}</td>

            <th>DY 6M
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.DY.message, 'O que é Dividend Yield ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["DY (6M) Acumulado"] || '---' }}</td>
        </tr>
        <tr>
            <th>QTD.ATIVOS
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.QTDATIVOS.message, 'Quantidade de Ativos em Fundo')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["Quantidade Ativos"] || 0 | number : '1.2-2' }}</td>

            <th>DY 12M
                <span class="cursor-pointer" (click)="openModalHelperDialog(helper.DY.message, 'O que é o Dividend Yield ?')">
                    <i class="fas fa-question-circle"></i>
                </span>
            </th>
            <td>{{ fundamentos["DY (12M) Acumulado"] || '---' }}</td>
        </tr>
    </table>
</div>