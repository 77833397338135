<nav class="navbar navbar-vertical navbar-expand-md"
  [ngClass]="isDarkMode ? 'navbar-dark bg-vs-dark2' : 'navbar-light bg-ligth'" id="sidenav-main">

  <div class="container-fluid">
    <!-- Toggler -->
    <button class="navbar-toggler d-none-desktop d-block-mobile" type="button" (click)="toggleCollapsedMobile()"
      aria-controls="sidenav-collapse-main">
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="d-none-desktop d-block-mobile text-center">
      <div class="pb-0 mb-0 font-weight-bold">Investidor</div>
      <div class="pt-0 mt-0 text-blue investidor-name font-italic">{{ getInvestidorName() || userInfo?.name }}</div>
    </div>

    <!-- User -->
    <div class="row ml-0 mr-0">
      <app-notifications class="pt-2 d-none-desktop"></app-notifications>
      <ul class="nav align-items-center d-none-desktop d-block-mobile">
        <li class="nav-item" ngbDropdown placement="bottom-right">
          <a class="nav-link" role="button" ngbDropdownToggle>
            <div class="media align-items-center">
              <span class="avatar avatar-sm rounded-circle">
                <img alt="Image placeholder" src="{{ userInfo?.photo }}">
              </span>
            </div>
          </a>
          <div class="dropdown-menu-arrow dropdown-menu-right" ngbDropdownMenu>
            <app-navbar-user></app-navbar-user>
          </div>
        </li>
      </ul>
    </div>

    <!-- Collapse -->
    <div class="collapse navbar-collapse" [ngbCollapse]="isCollapsed" id="sidenav-collapse-main"
      [ngClass]="isDarkMode ? 'bg-vs-dark2' : ''">
      <!-- Collapse header -->
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a routerLinkActive="active" [routerLink]="['/carteira']">
              <img src="./assets/img/brand/dp.jpeg" class="logo logo-height" alt="logo do dlombelloplanilhas">
            </a>
          </div>
          <div class="col-6 collapse-close">
            <span type="button" class="navbar-toggler" (click)="toggleCollapsedMobile()">
              <span [ngClass]="isDarkMode ? 'toggle-dark-mode' : ''"></span>
              <span [ngClass]="isDarkMode ? 'toggle-dark-mode' : ''"></span>
            </span>
          </div>
        </div>
      </div>

      <!-- Navigation -->
      <ul class="navbar-nav marginttopneg-40">
        <span class="pl-2">
          <img *ngIf="isCollapsed" src="./assets/img/brand/dp.jpeg" class="logo side-logo"
            alt="logo do dlombelloplanilhas">
        </span>

        <li class="nav-item d-none d-sm-block">
          <span role="button" class="nav-link d-block-desktop d-none-mobile mt-2" (click)="toggleMenu()">
            <i class="fa fa-bars" aria-hidden="true" *ngIf="!menuOpened"></i>
            <i class="fa fa-times" aria-hidden="true" *ngIf="menuOpened"></i>
          </span>
        </li>

        <li *ngFor="let menuItem of menuItems" class="{{menuItem.class}} nav-item">
          <a routerLinkActive="active" [routerLink]="[menuItem.path]" class="nav-link pt-1 pb-1"
            tourAnchor={{menuItem.title}}>
            <i class="{{menuItem.icon}}"></i>
            <span class="nav-text">
              {{menuItem.title}}
            </span>
          </a>
        </li>
      </ul>

      <!-- Divider -->
      <hr class="my-2 nav-text">

      <!-- Links -->
      <h6 class="navbar-heading text-muted nav-text">Links</h6>
      <ul class="navbar-nav">
        <li class="nav-item nav-text">
          <a class="nav-link pt-0 pb-0" [routerLink]="'/alpha'">
            <i class="fas fa-user-circle"></i>
            <span class="nav-text">
              Planos
            </span>
          </a>
        </li>
        <li class="nav-item nav-text">
          <a class="nav-link pt-0 pb-0" href="https://www.dlombelloplanilhas.com/" target="_blank">
            <i class="ni ni-spaceship"></i>
            <span class="nav-text">
              Site
            </span>
          </a>
        </li>
        <li class="nav-item nav-text">
          <a class="nav-link pt-0 pb-0" href="https://www.dlombelloplanilhas.com/faq" target="_blank"
            tourAnchor="faqLink">
            <i class="fas fa-question-circle"></i>
            <span class="nav-text">
              FAQ
            </span>
          </a>
        </li>
        <li class="nav-item nav-text">
          <a class="nav-link pt-0 pb-0" href="https://leitordenotas.com.br/" target="_blank">
            <i class="fas fa-book-open"></i>
            <span class="nav-text">
              Leitor de Notas
            </span>
          </a>
        </li>
      </ul>

      <hr class="my-2 nav-text">

      <ul class="navbar-nav" *ngIf="!menuOpened">
        <li role="button" class="nav-link" (click)="toggleDarkMode()">
          <i class="fas fa-moon" *ngIf="isDarkMode"></i>
          <i class="fas fa-sun" *ngIf="!isDarkMode"></i>
          <span class="nav-text"> Mudar Tema </span>
        </li>
      </ul>

      <div class="text-center" *ngIf="menuOpened">
        <div class="" style="font-size: 0.8rem;" triggers="mouseenter:mouseleave">
          {{ isDarkMode ? 'Dark' : 'Light' }}
        </div>
        <i class="fas fa-sun"></i>
        <label class="custom-toggle mb-0 mx-2">
          <input type="checkbox" name='isDarkMode' [checked]="isDarkMode" (change)="toggleDarkMode()">
          <span class="custom-toggle-slider rounded-circle"></span>
        </label>
        <i class="fas fa-moon"></i>
      </div>

    </div>
  </div>
</nav>