/* eslint-disable @angular-eslint/no-empty-lifecycle-method */
import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TourService } from 'ngx-ui-tour-ngx-bootstrap';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { ModalMultiInvestidorComponent, modalMultiInvestidorOptions } from '../modal-multi-investidor/modal-multi-investidor.component';
import { ModalProfileComponent } from '../modal-profile/modal-profile.component';
import { ModalTermosUsoComponent } from '../modal-termos-uso/modal-termos-uso.component';

@Component({
    selector: 'app-navbar-user',
    templateUrl: './navbar-user.component.html',
    styleUrls: ['./navbar-user.component.scss'],
    standalone: false
})
export class NavbarUserComponent implements OnInit {

  public windowWidth = window.innerWidth

  constructor(
    private _modalService: NgbModal,
    private _authService: AuthService,
    private _tourService: TourService,
    private _userService: UserService,
  ) { }

  restartTour() {
    this._tourService.start();
  }
  
  ngOnInit(): void {
  }

  openModalProfile() {
    const modalRef = this._modalService.open(ModalProfileComponent, { size: 'sm', backdrop: 'static', backdropClass: 'backdropClass' });
  }

  openModalTermosUso() {
    const modalRef = this._modalService.open(ModalTermosUsoComponent, { size: 'xl', backdrop: 'static', backdropClass: 'backdropClass' });
  }
  openModalmultiInvestidor() {
    const modalRef = this._modalService.open(ModalMultiInvestidorComponent, modalMultiInvestidorOptions);
  }

  logout() {
    this._authService.logout()
  }

}
