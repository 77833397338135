export default class StaticParams {

    static planosAlpha = {
        //"starter": { nome: "TESTE", url: "https://mpago.la/2scmzc5", cod_external: "TEST_5_NLN_6M", valor: "R$ 1,00" },

        "oldpremium": { nome: "PREMIUM", url: "", cod_external: "UALN_001_20K_A", valor: 49.99 },
        "oldultimate": { nome: "ULTIMATE", url: "", cod_external: "UALN_001_ILM_A", valor: 64.99 },

        "starter": { nome: "STARTER", url: "https://mpago.la/2scmzc5", cod_external: "STARTER_01_LN20K_1A", valor: 49.99, valor_full: 59.99 },
        "premium": { nome: "PREMIUM", url: "https://mpago.la/1njPcpV", cod_external: "PREMIUM_01_LN20K_1A", valor: 99.99, valor_full: 119.98 },
        "ultimate": { nome: "ULTIMATE", url: "https://mpago.la/1w4vfND", cod_external: "ULTIMATE_01_LNILM_1A", valor: 149.99, valor_full: 194.97 },
        "multi3": { nome: "MULTI INVESTIDOR 3", url: "https://mpago.la/2eYaqc2", cod_external: "MULTI_03_LN20K_1A", valor: 199.98, valor_full: 299.97 },
        "multi5": { nome: "MULTI INVESTIDOR 5", url: "https://mpago.la/2TahAcu", cod_external: "MULTI_05_LN20K_1A", valor: 299.97, valor_full: 499.95 },
        "multi10": { nome: "MULTI INVESTIDOR 10", url: "https://mpago.la/16SXHpS", cod_external: "MULTI_10_LN20K_1A_550", valor: 549.92, valor_full: 999.90 },

        "premium_bf": { nome: "PREMIUM", url: "https://mpago.la/1njPcpV", cod_external: "PREMIUM_01_LN20K_1A", valor: 84.99, valor_full: 119.98 },
        "ultimate_bf": { nome: "ULTIMATE", url: "https://mpago.la/1w4vfND", cod_external: "ULTIMATE_01_LNILM_1A", valor: 124.99, valor_full: 194.97 },
        "multi3_bf": { nome: "MULTI INVESTIDOR 3", url: "https://mpago.la/2eYaqc2", cod_external: "MULTI_03_LN20K_1A", valor: 174.99, valor_full: 299.97 },
        "multi5_bf": { nome: "MULTI INVESTIDOR 5", url: "https://mpago.la/2TahAcu", cod_external: "MULTI_05_LN20K_1A", valor: 249.99, valor_full: 499.95 },
    }

    static fnBrowserDetect() {
        let userAgent = navigator.userAgent;
        if (userAgent.match(/edg/i)) { return "edge" }
        if (userAgent.match(/chrome|chromium|crios/i)) { return "chrome" }
        if (userAgent.match(/firefox|fxios/i)) { return "firefox" }
        if (userAgent.match(/safari/i)) { return "safari" }
        if (userAgent.match(/opr\//i)) { return "opera" }
        return "No browser detection";
    }

    // CSS
    static tableTextClassStyleColor() {
        return {
            'AÇÃO': `text-blue`,
            'FII': `text-green`,
            'ETF': `text-orange`,
            'BDR': `text-purple`,

            'STOCK': `text-indigo`,
            'REIT': `text-teal`,
            'ETF_': `text-gray-dark`,

            'CRIPTO': `text-red`,
            '.FUT': `text-dark`,
            'OUTROS': 'text-dark',

            '!ERROR': `text-red bg-dark`,

            '<R$10,00': `text-grey`,
            'ISENTO': `text-grey`,
            'PAGAR DARF': `text-red`,
            'PAGAR DARF GCAP': `text-red`,
            '-12-': `text-white bg-dark`,
        }
    }

    // STATICS
    static verificaCorretora(nome_corretora) {
        if (!nome_corretora) {
            return 'INFORMAR';
        }
        nome_corretora = nome_corretora.toString().toUpperCase().replace(new RegExp(' ', 'g'), '');
        if (nome_corretora.indexOf('CAIXA') >= 0) {
            return 'CEF';
        } else if (nome_corretora.indexOf('RAMA') >= 0) {
            return 'ORAMA';
        } else if (nome_corretora.indexOf('GORA') >= 0) {
            return 'AGORA';
        }

        let corretoras: any = Object.keys(this.corretoras).toString().toUpperCase()
        corretoras = corretoras.split(',');

        for (let i in corretoras) {
            if (nome_corretora.indexOf(corretoras[i]) >= 0) {
                return corretoras[i];
            }
        }
        return nome_corretora;
    }

    static outros() {
        return {
            classe: [
                "FUNDO", "R.FIXA", "R.VARIAVEL", "M.MERCADO", "CRIPTO", "OFFSHORE", "OUTROS"
            ],
            setor: [
                "TIT.PUBLICO", "LC", "LCI", "LCA", "CDB", "RDB", "COE", "CRI", "CRA", "LIG", "R.FIXA", "POUPANCA", "DEBENTURE", "FGTS",
                "AÇÃO", "M.MERCADO", "CAMBIAL", "OUTROS", "PREVIDENCIA", "IMOVEL"
            ],
            subsetor: [
                "CDI", "SELIC", "IPCA", "PRE", "IGPM",
                "IBOV", "IFIX", "DOLAR", "OUTROS"
            ],
            rfixa: ["CDB", "LCI", "LCA", "LC", "RDB"],
            index: ["CDI", "SELIC", "IPCA", "PRE", "IGPM"]
        }
    }

    static cei: any = {
        operacoes: {
            "Compra": "C",
            "Venda": "V",
            "Transferência - Liquidação": "C",
            "Cobrança de Taxa Semestral": "TAXA",
            "Desdobro": "DESDOBRAMENTO",
            "Grupamento": "GRUPAMENTO",
        },
        eventos: {
            "Atualização": "SUB",
            "Bonificação em Ativos": "BN",
            "Incorporação": "AT",
            "Leilão de Fração": "V",
        },
        proventos: {
            "Dividendo": "DIVIDENDO",
            "Rendimento": "RENDIMENTO",
            "Juros Sobre Capital Próprio": "JSCP",
            "Empréstimo": "BTC",
            "Reembolso": "REEMBOLSO(DIV)",
            "Amortização": "AMORTIZACAO"
        }
    }


    static indexSymbols = 'IBOVESPA,IFIX,SP500,NASDAQ,USDBRL,EURBRL,BTCUSD,ETHUSD'.split(',')

    static currencySymbols = {
        BRL: "R$",
        USD: "$",
        AUD: "AU$",
        CAD: "CA$",
        CHF: "₣",
        CLP: "CL$",
        EUR: "€",
        GBP: "£",
        GBX: "£x",
        HKD: "HK$",
        NZD: "NZ$",
        SEK: "kr",
        JPY: "¥",
        SGD: "SG$",
    }

    static corretoras: any = {
        "Agora": { "nome": "Agora", "cnpj": "74014747000135", "razao_social": "AGORA CTVM S.A." },
        "Ativa": { "nome": "Ativa", "cnpj": "33775974000104", "razao_social": "ATIVA INVESTIMENTOS S.A. C.T.C.V." },
        "BB": { "nome": "BB", "cnpj": "24933830000130", "razao_social": "BB-BANCO DE INVESTIMENTO S.A." },
        "BTG": { "nome": "BTG", "cnpj": "43815158000122", "razao_social": "BTG PACTUAL CTVM S/A" },
        "C6Bank": { "nome": "C6Bank", "cnpj": "31872495000172", "razao_social": "Banco C6 - SA" },
        "CEF": { "nome": "CEF", "cnpj": "00360305000104", "razao_social": "CAIXA ECONOMICA FEDERAL" },
        "Clear": { "nome": "Clear", "cnpj": "02332886001178", "razao_social": "CLEARCORRETORA-GRUPOXP" },
        "CMCapital": { "nome": "CMCapital", "cnpj": "02685483000130", "razao_social": "CM CAPITAL MARKETS CCTVM LTDA" },
        "Easynvest": { "nome": "Easynvest", "cnpj": "62169875000179", "razao_social": "EASYNVEST - TITULO CORRETORA DE VALORES SA" },
        "NuInvest": { "nome": "NuInvest", "cnpj": "62.169.875/0001-79", "razao_social": "NU INVEST CORRETORA DE VALORES S.A." },
        "Genial": { "nome": "Genial", "cnpj": "27652684000162", "razao_social": "GENIAL INVESTIMENTOS CORRETORA DE VALORES MOBILIARIOS S.A." },
        "Guide": { "nome": "Guide", "cnpj": "065913436000117", "razao_social": "Guide Investimentos Sa Corretora de Valores" },
        "Icap": { "nome": "Icap", "cnpj": "09105360000122", "razao_social": "ICAP DO BRASIL CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA" },
        "Inter": { "nome": "Inter", "cnpj": "18945670000146", "razao_social": "INTER DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS" },
        "Itau": { "nome": "Itau", "cnpj": "61194353000164", "razao_social": "ITAU CORRETORA DE VALORES S/A" },
        "Kirton": { "nome": "Kirton", "cnpj": "58229246000110", "razao_social": "Bradesco-kirton Corretora de Cambio S.A." },
        "Mirae": { "nome": "Mirae", "cnpj": "12392983000138", "razao_social": "MIRAE ASSET WEALTH MANAGEMENT (BRAZIL) CCTVM LTDA" },
        "Modal": { "nome": "Modal", "cnpj": "30723886000162", "razao_social": "BANCO MODAL S.A." },
        "ModalMais": { "nome": "ModalMais", "cnpj": "05389174000101", "razao_social": "MODAL D.T.V.M. LTDA" },
        "Necton": { "nome": "Necton", "cnpj": "52904364000108", "razao_social": "Necton Investimentos S.A." },
        "NovaFutura": { "nome": "NovaFutura", "cnpj": "04257795000179", "razao_social": "NOVA FUTURA CTVM LTDA" },
        "Orama": { "nome": "Orama", "cnpj": "13293225000125", "razao_social": "ORAMA DTVM" },
        "Rico": { "nome": "Rico", "cnpj": "02332886001682", "razao_social": "RICO INVESTIMENTOS - GRUPO XP" },
        "Santander": { "nome": "Santander", "cnpj": "51014223000149", "razao_social": "Santander Corretora de Cambio e Valores Mobiliarios S.A." },
        "Socopa": { "nome": "Socopa", "cnpj": "62285390000140", "razao_social": "SOCOPA SOCIEDADE CORRETORA PAULISTA SA" },
        "Terra": { "nome": "Terra", "cnpj": "03751794000113", "razao_social": "TERRA INVESTIMENTOS DISTRIBUIDORA DE TITULOS E VALORES MOBILIARIOS LTDA" },
        "Toro": { "nome": "Toro", "cnpj": "29162769000198", "razao_social": "TORO CORRETORA DE TITULOS E VALORES MOBILIARIOS LTDA" },
        "Vitreo": { "nome": "Vitreo", "cnpj": "34711571000156", "razao_social": "Vitreo Distribuidora de Títulos e Valores Mobiliários S.A." },
        "Warren": { "nome": "Warren", "cnpj": "92875780000131", "razao_social": "Warren Corretora de Valores Mobiliarios e Cambio LTDA." },
        "XP": { "nome": "XP", "cnpj": "02332886000104", "razao_social": "XP INVESTIMENTOS CCTVM S.A." },

        "BitBlue": { "nome": "BitBlue", "cnpj": "28.789.247/0001-58", "razao_social": "B BLUE TECNOLOGIA E SERVIÇOS DIGITAIS S.A" },
        "BitCambio": { "nome": "BitCambio", "cnpj": "19.004.882/0001-91", "razao_social": "Citar Tech Eireli" },
        "BitcoinTrade": { "nome": "BitcoinTrade ", "cnpj": "28.640.024/0001-24", "razao_social": "PEERTRADE DIGITAL LTDA" },
        "BitPreco": { "nome": "BitPreco ", "cnpj": "29.738.313/0001-23", "razao_social": "Cbtc Servicos Digitais LTDA" },
        "Braziliex": { "nome": "Braziliex  ", "cnpj": "27.433.963/0001-35", "razao_social": "BRAZILIEX INTERMEDIAÇÃO DE NEGÓCIOS LTDA" },
        "Coinext": { "nome": "Coinext ", "cnpj": "29.242.868/0001-80", "razao_social": "COINEXT SERVICOS DIGITAIS LTDA" },
        "Foxbit": { "nome": "Foxbit ", "cnpj": "21.246.584/0001-50", "razao_social": "Foxbit Servicos Digitais S.A." },
        "MercadoBitcoin": { "nome": "MercadoBitcoin ", "cnpj": "18.213.434/0001-35", "razao_social": "MERCADO BITCOIN SERVIÇOS DIGITAIS LTDA" },
        "NovaDAX": { "nome": "NovaDAX ", "cnpj": "31.745.082/0001-27", "razao_social": "NOVADAX BRASIL PAGAMENTOS LTDA" },
        "NoxBitcoin": { "nome": "NoxBitcoin ", "cnpj": "30.259.965-0001/64", "razao_social": "Nox Trading LTDA" },
        "Profitfy": { "nome": "Profitfy ", "cnpj": "29.542.101/0001-76", "razao_social": "Negocios Digitais S/S LTDA" },
        "Walltime": { "nome": "Walltime ", "cnpj": "19.865.285/0001-51", "razao_social": "Walltime Servicos Digitais LTDA" },

        "Avenue": { "nome": "Avenue ", "cnpj": null, "razao_social": "Avenue Securities LLC" },
        "Passfolio": { "nome": "Passfolio ", "cnpj": null, "razao_social": "Passfolio Securities LLC" },
        "IBKR": { "nome": "Interactive Brokers ", "cnpj": null, "razao_social": "Interactive Brokers LLC" },
        "TDAMERITRADE": { "nome": "TD Ameritrade", "cnpj": null, "razao_social": "TD Ameritrade Inc." },
        "CHARLESSCHWAB": { "nome": "Charles Schwab", "cnpj": null, "razao_social": "Charles Schwab & Co. Inc." },
    }

    static pieChart: any = {
        chart: { type: 'pie' },
        title: { text: '' },
        subtitle: { text: "" },
        tooltip: { pointFormat: '<strong>{point.vlr_mercado:.2f} {point.percentage:.2f}%</strong>' },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                borderColor: '#949494',
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: {point.percentage:.2f}%',
                    style: { fontSize: '9px', }
                }
            }
        }
    }

    static lineChart: any = {
        title: { text: "" },
        subtitle: { text: "" },
        yAxis: {
            title: { text: "" },
        },
        xAxis: { categories: [], },
        legend: { align: 'center', verticalAlign: 'top', borderWidth: 0 },
        plotOptions: {
            series: {
                label: { connectorAllowed: false },
                marker: { enabled: false },
                lineWidth: 2
            }
        },
        tooltip: { shared: true, crosshairs: true },
        responsive: {
            rules: [{
                condition: { maxWidth: 500 },
                chartOptions: {
                    legend: { layout: 'horizontal', align: 'center', verticalAlign: 'top' }
                }
            }]
        }
    }

    static stackBarChart: any = {
        chart: { type: 'column' },
        title: { text: '' },
        subtitle: { text: "" },
        xAxis: { categories: [] },
        yAxis: {
            title: { text: '' }
        },
        legend: { align: 'center', verticalAlign: 'top', borderWidth: 0 },
        tooltip: { shared: true, },
        plotOptions: {
            column: { stacking: 'normal' }
        },
        series: []
    }

    static helper: any = {
        TWR: {
            message: `
            O retorno ponderado no tempo (TWR) multiplica os retornos para cada subperíodo ou período de retenção, que os vincula mostrando como os retornos são compostos ao longo do tempo.

            Ele ajuda a eliminar os efeitos de distorção nas taxas de crescimento criados por entradas e saídas de dinheiro.`
        },

        XTIR: {
            message: `
            A Taxa Interna de Retorno (TIR) é uma métrica financeira que ajuda a avaliar a rentabilidade de um investimento ao longo do tempo, levando em conta os fluxos de caixa que não são necessariamente periódicos. Ela indica a taxa de crescimento, que pode ser ao mês (a.m.) ou ao ano (a.a.) que iguala os fluxos de caixa gerados pelo investimento, ou seja, quando o dinheiro investido é compensado pelos lucros obtidos.`
        },

        IMPORTCEI: {
            message: `
            1. Acesse o <a href="https://www.investidor.b3.com.br" target="_blank">Portal B3 Investidor</a>, e efetue o acesso com suas credenciais;
        
            2. Clique em "Extrato" no menu lateral;
            
            3. Em "Extrato", escolha entre as abas "Posição" (para exportar sua posição na B3 para comparar com o app), "Movimentação" ou "Negociação" para importar as operações. Para Proventos vá em "Proventos" e depois clique na aba de "Recebidos";

            3. Clique em "Filtrar" no canto superior direito e selecione o período desejado;

            4. Clique no botão "Baixar Extrato" e selecione o formato "Arquivo em Excel" e em seguida clique no botão "Baixar";

            5. De volta ao WebApp, na tela de Extratos, arraste o arquivo baixado para o local indicado ou selecione o arquivo em seu computador;
            
            6. Uma nova tela com as informações do extrato abrirá, faça as alterações que achar necessário e clique em salvar ou ignorar.`

        },

        DASHBOARD: {
            proventosPorAtivo: `
            Selecione uma ou mais classes para ver o gráfico de Proventos por Ativo ao invés de Proventos por Classes.`
        },

        PL: {
            message: `
            Preço/Lucro é uma medida financeira usada para saber quanto os investidores estão dispostos a pagar por cada real que a empresa lucra.
            
            É como se fosse um preço que você paga para ter uma parte dos lucros da empresa. Quanto menor for o Preço/Lucro, mais barata é a empresa em relação aos seus lucros.
            
            Sua fórmula é: Preço da ação / Lucro por ação (LPA)`
        },

        ROE: {
            message: `
            O ROE é um indicador financeiro capaz de medir a capacidade de uma empresa de gerar valor através dos recursos que ela possui inicialmente. Ou seja: mostra quanto é gerado de lucro, de forma percentual, em relação ao capital investido.
            
            Sua fórmula é: (Lucro Líquido / Patrimônio Líquido) * 100`
        },

        DY12M: {
            message: `
            O Dividend Yield (Rendimento do Dividendo) é uma medida utilizada para avaliar o retorno que um investidor pode obter com relação aos dividendos pagos por uma determinada empresa em relação ao preço de suas ações expressa em porcentagem.
            
            Nesse caso o período indicado são os últimos 12 meses.
            
            Sua fórmula é: Dividendo pago / Preço do ativo`
        },

        MRGLIQ: {
            message: `
            A Margem Líquida é uma medida de rentabilidade que indica a porcentagem de lucro líquido que uma empresa obtém em relação à sua receita total. 
            
            Sua fórmula é: (Lucro Líquido / Receita Total) * 100`
        },

        REND: {
            message: `
            Rendimento é uma medida de retorno ou de ganho obtido em relação a um investimento ou ativo financeiro. 
            Ele pode ser expresso em termos absolutos, como um valor monetário, ou em termos relativos, como uma porcentagem.
            
            No caso está representado através do valor monetário gerado pelo fundo no último mês.`
        },

        VPA: {
            message: `
            O VPA (Valor Patrimonial por Ação) é uma medida financeira que representa o valor contábil de uma empresa dividido pelo número total de ações emitidas.

            Sua fórmula é: Patrimônio Líquido / Número de Ações em Circulação`
        },

        PVPA: {
            message: `
            O P/VPA é uma métrica financeira utilizada por investidores para avaliar se o preço atual de mercado de uma ação está acima ou abaixo de seu valor contábil.

            Sua fórmula é: Preço de mercado da ação / Valor Patrimonial por Ação`
        },

        DY: {
            message: `
            O Dividend Yield (Rendimento do Dividendo) é uma medida utilizada para avaliar o retorno que um investidor pode obter com relação aos dividendos pagos por uma determinada empresa em relação ao preço de suas ações expressa em porcentagem.
            
            Nesse caso o valor apresentado é do último dividendo pago.
            
            Sua fórmula é: Dividendo pago / Preço do ativo`
        },

        DRAW: {
            message: `
            O Drawdown é uma medida que se refere à diferença entre o valor máximo de um investimento e seu valor atual em um determinado período. É a medida da queda de preço ou desvalorização de um ativo ou portfólio em relação ao seu ponto mais alto anterior.`
        },

        PMFX: {
            message: `
            O PM FX é o Preço Médio na moeda original do ativo.`

        },

        PTAXUSD: {
            message: `
            O PTAX USD é o PTAX referente ao dólar independente da moeda base o ativo.`
        },

        PMUSD: {
            message: `
            O PM USD é o preço médio em USD independente da moeda original do ativo e da moeda base do sistema.`
        },

        PATRLIQ: {
            message: `
            O Patrimônio Líquido é a diferença entre os ativos (bens e direitos) e os passivos (obrigações financeiras) de uma empresa. Ele representa o valor líquido que pertence aos proprietários após o pagamento de todas as dívidas. É uma medida da saúde financeira e do valor líquido da empresa.`
        },

        PLPA: {
            message: `
            O P/LPA é uma medida que relaciona o preço de uma ação ao lucro por ação da empresa. Indica o quanto o mercado está disposto a pagar pelo lucro gerado por cada ação. Um valor alto significa que o mercado valoriza mais o lucro, enquanto um valor baixo indica que o mercado valoriza o lucro de forma mais modesta.
            
            Sua fórmula é: Preço da Ação / Lucro por Ação`
        },

        DIVBRUTPATR: {
            message: `
            A DIV.BRUTA/PATR. é uma métrica financeira que indica a proporção da dívida total da empresa em relação ao seu patrimônio líquido. Em outras palavras, ela mostra o quanto a empresa deve em relação aos recursos que foram investidos pelos acionistas. Uma relação alta pode indicar que a empresa está muito endividada em relação aos seus recursos próprios, o que pode ser um sinal de risco financeiro. Por outro lado, uma relação baixa pode indicar que a empresa possui uma estrutura de capital saudável, com uma quantidade adequada de dívidas em relação aos recursos próprios.

            Sua fórmula é: Dívida Bruta / Patrimônio Líquido`
        },

        MRGEBIT: {
            message: `
            A Margem EBITDA é uma medida financeira que indica a rentabilidade operacional de uma empresa, antes dos juros, impostos, depreciação e amortização. EBITDA significa "Earnings Before Interest, Taxes, Depreciation, and Amortization" (Lucros antes de Juros, Impostos, Depreciação e Amortização). Essa métrica é útil para avaliar a eficiência operacional de uma empresa, pois exclui os efeitos de fatores financeiros e contábeis não relacionados à operação principal. Uma margem EBITDA alta indica que a empresa está gerando boas margens operacionais, enquanto uma margem EBITDA baixa pode sugerir desafios financeiros ou operacionais.
            
            Sua fórmula é: (EBITDA / Receita Total) * 100`
        },

        QTDATIVOS: {
            message: `
            Um Fundo Imobiliário de tijolo pode possuir vários imóveis em diferentes lugares, como prédios comerciais, shoppings ou galpões. Cada um desses imóveis é contabilizado separadamente, representando uma parte do valor total do fundo. Assim, os investidores têm participação proporcional em cada um dos ativos do fundo. Essa quantidade define quantos imóveis diferentes o Fundo tem.`

        },

        IMPORTCS: {
            message: `
            1. Acesse a área logada da corretora com sua credenciais;

            2. Clique em “Accounts” e em seguida clique em “History” na linha mais abaixo;

            3. Filtre o período deseja e, no canto superior direito, clique em “Export”;

            4. De volta ao WebApp, na tela de Extratos, arraste o arquivo baixado para o local indicado ou selecione o arquivo em seu computador;

            5. Uma nova tela com as informações do extrato abrirá, faça as alterações que achar necessário e clique em salvar ou ignorar.`
        },

        IMPORTAVENUE: {
            message: `
            1. Acesse a área logada da corretora com suas credenciais;

            2. Acesse o menu "Relatórios" e em seguida clique em "Conta de Investimentos" na coluna “Avenue US”;

            3. Filtre o período desejado e clique em “Download do CSV BR”;

            4. De volta ao WebApp, na tela de Extratos, arraste o arquivo baixado para o local indicado ou selecione o arquivo em seu computador;
 
            5. Uma nova tela com as informações do extrato abrirá, faça as alterações que achar necessario e clique em salvar ou ignorar.`
        },

        IMPORTIB: {
            message: `
            1. Acesse a área logada da corretora com suas credenciais;

            2. Acesse o menu “Performance & Reports” e em seguida clique em “Statements”;

            3. Clique em “Activity”, escolha o período no filtro e depois faça o download do arquivo em CSV.

            4. De volta ao WebApp, na tela de Extratos, arraste o arquivo baixado para o local indicado ou selecione o arquivo em seu computador;

            5. Uma nova tela com as informações do extrato abrirá, faça as alterações que achar necessario e clique em salvar ou ignorar.`
        }

    }

    static vencimentoOpcoes: any = {
        'A': 1, 'M': 1,
        'B': 2, 'N': 2,
        'C': 3, 'O': 3,
        'D': 4, 'P': 4,
        'E': 5, 'Q': 5,
        'F': 6, 'R': 6,
        'G': 7, 'S': 7,
        'H': 8, 'T': 8,
        'I': 9, 'U': 9,
        'J': 10, 'V': 10,
        'K': 11, 'W': 11,
        'L': 12, 'X': 12,
    }

    static versionNotifications: any = [
        {
            date: '2022-10-23',
            titulo: 'v1.0.14',
            mensagem: `- Melhorias gerais na central de notificações.
- Inserido alertas e avisos na central de notificações.
- Alertas automáticos de vencimento de plano, Darf e atualização dos dados mensais.`,
        },
        {
            date: '2022-10-22',
            titulo: 'v1.0.13',
            mensagem: `- Melhoria no cálculo da XTIR do DASHBOARD.
- Implementado XTIR no PORTFÓLIO.
- Melhoria na importação de dados de MULTI-INVESTIDOR.`,
        },
        {
            date: '2022-10-21',
            titulo: 'v1.0.12',
            mensagem: `- Opções de notificação dos fatos relevantes e notícias por grupos.
- Atualização do PTAX de liquidação automática em operações quando ficar disponível.`,
        },
        {
            date: '2022-10-20',
            titulo: 'v1.0.11',
            mensagem: `- Implementação da central de notificações.
- Melhoria na abertura do tour e termos de uso.`,
        },
        {
            date: '2022-10-10',
            titulo: `v1.0.10`,
            mensagem: `- Implementado opção de login no app com email/senha.
            - Para cadastrar senha em uma conta já ativa, basta definir uma nova em "Esqueceu a senha?" no login. 
            - Implementado propagandas Google Adsense para os usuários sem plano ativo.
            - Correções e melhorias gerais no controle de cache do sistema.`
        },
        {
            date: '2022-10-04',
            titulo: `v1.0.09`,
            mensagem: `- Melhoria na lógica de leitura de extratos da corretora IBKR.
            - Filtros dropdown nas páginas de OPERAÇÕES e PROVENTOS. 
            - Otimização do carregamento dos dados para cálculo do PORTFÓLIO e cache do sistema.`
        },
        {
            date: '2022-09-30',
            titulo: `v1.0.08`,
            mensagem: `- Verificação da quantidade de carteiras de alocação de um ativo no PORTFÓLIO.
            - Edição das carteiras de alocação dos ativos pela página do PORTFÓLIO ao clicar no código do ativo.
            - Importação de operações e proventos do extrato das corretoras: Passfólio, TD Ameritrade e Charles Schwab.`
        },
        {
            date: '2022-09-25',
            titulo: `v1.0.07`,
            mensagem: `- Animação dos botões de informações dos ativos lidas no PORTFÓLIO.
          - Tratamento dos dados de PROVENTOS para não salvar ativos inexistentes no banco de dados.
          - Inserido suporte a restauração dos dados de DARF pagas através do arquivo local JSON.
          - Na busca de ativos no PORTFÓLIO retorna alerta para os ativos não encontrados no DB.
          - Tratamento de erros nos cadastros devido a números com notação científica.
          - Correção do erro na sugestão de ALOCAÇÃO de ativos internacionais.
          - Validação se existe o ptax D+2 para ativos com moeda base e moeda ativo diferentes.
          - Suporte a moedas e PTAX NZD (Dólar Neozelandês).
          - Exemplo para uso do External Token no Google Scripts.
          - Edição de PROVENTOS em lista de forma simultânea na tabela jexcel.
          - Edição de OPERAÇÕES em liasta em preparação. Será disponibilizado em breve.`
        },
        {
            date: '2022-09-02',
            titulo: `v1.0.06`,
            mensagem: `- Prejuízos tributáveis nas configuração de cálculos sempre negativos em DARF.       
          - Pagamento de PLANOS via PIX e QR code, com vinculação do pagamento automática na conta logada no App.
          - Pesquisa de um ativo e suas informações de cadastro, histórico, gráfico e fundamentos no PORTFÓLIO.
          - Correção do gráfico de histórico ajustado de ativos do Tesouro Direto.
          - Otimização dos cálculos de histórico e gráficos do dashboard.
          - Período anual dos dados dos gráficos e tabela de dados em DASHBOARD.
          - Ajuste no % dos índices IBOV/IFIX/IVVB nos gráficos conforme moeda base selecionada no sistema.
          - Em CONFIGURAÇÃO a possibilidade de gerar token externo para uso dos dados. Ex: planilha v6 ou aplicativos parceiros.
          - Importação de operações e proventos pelo extrato da corretora Interactive Brokers (IBKR-USA).
          - Paginação na inserção de dados de operações e proventos pelo modal Jexcel em dados muito grandes.`
        },
        {
            date: '2022-07-25',
            titulo: `v1.0.05`,
            mensagem: `- Layout de navegação em abas entre as tabelas de DARF nacional, internacional e cripto.
          - Coluna para inserir/salvar valor de DARF pago no mês nas tabelas nacional, internacional e cripto.
          - Informação de data limite para pagamento da DARF na descrição do status.
          - Download do xls (csv) da tabela de dados com histórico para usuários PREMIUM ou superior.
          - Tratamento dos dados no cadastro para aceitar valores com simbolos monetários (Ex.: R$ 20,00).
          - Implementado tema darkMode em alguns novos componentes do site.
          - Notificação de novas atualizações de versão ao abrir sistema.
          - Status de finalização do tour inicial persistente salvo no servidor.
          - Status de opção de tema dark/light persistente salvo no servidor.`
        },
        {
            date: '2022-07-10',
            titulo: `v1.0.04`,
            mensagem: `- Ao clicar no código do ativo em ALOCAÇÃO abre modal de informações.
          - Melhoria no layout da página de configuração.
          - Importação dos dados do XLS do CEI aceitando extratos: "Movimentação", "Negociação" ou "Proventos".
          - Importação do extrato de "Posição" do CEI para comparação com os dados inseridos no sistema Web.
          - Implementado tema darkMode em alguns novos componentes do site e usado paleta de cores dark.`
        },
        {
            date: '2022-07-07',
            titulo: `v1.0.03`,
            mensagem: `- Ao clicar no código do ativo em PORTFÓLIO abre modal de informações.
          - Possibilidade de mover a linha de OPERAÇÔES de um mesmo ativo em uma mesma data asc ou desc.
          - Implementado tema darkMode no rodapé e header de cards de resumo superior.
          - Correções de pequenos bugs e melhorias.`
        },
        {
            date: '2022-06-26',
            titulo: `v1.0.01`,
            mensagem: `- Correção do bug do gráfico de pizza não carregar por carteira em PORTFÓLIO.
          - Inserido filtro por carteira de alocação no PORTFÓLIO.
          - Inserido % do peso do valor de mercado dos ativos filtrados no PORTFÓLIO.
          - Coluna de lucro percentual na tabela de OPERAÇÕES.
          - Ajuste de cor titulos das tabelas (cinza: usuários, preto: cálculados).
          - Card de resumo com total de proventos dos ativos filtrados.
          - Nome Pregão dos ativos ao passar o mouse no cod dos ativos.
          - Cálculo da quantidade de sugestão levando em conta o ptax da moeda do ativo.
          - Extrato de movimentação do novo CEI verificando dados duplicados.
          - Extrato financeiro da Avenue verificando dados duplicados.
          - Correções de bugs e melhorias diversas.`
        },
        {
            date: '2022-06-25',
            titulo: `v1.0.00`,
            mensagem: `- Início do histórico de versões.`
        },
    ]

}
