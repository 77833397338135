/* src/app/components/sidebar/sidebar.component.scss */
.logo {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 1px #ffffff;
  box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
}
.side-logo {
  display: block;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  width: 20%;
  height: 20%;
}
@media only screen and (min-width: 768px) {
  .logo-height {
    max-height: inherit !important;
    height: 6rem !important;
  }
  .navbar-vertical {
    box-shadow: none !important;
  }
  .marginttopneg-40 {
    margin-top: -40px;
  }
  .nav-text {
    display: none;
    font-size: 13px;
  }
  .d-none-desktop {
    display: none !important;
  }
  .d-block-desktop {
    display: block !important;
  }
}
@media only screen and (max-width: 767px) {
  .logo-height {
    max-height: inherit !important;
    height: 3rem !important;
  }
  .d-none-mobile {
    display: none !important;
  }
  .d-block-mobile {
    display: block !important;
  }
}
.investidor-name {
  font-size: small;
}
/*# sourceMappingURL=sidebar.component.css.map */
