// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.badge {
  position: relative;
  top: -7px;
  left: -20px;
  border: 1px solid;
  border-radius: 50%;
}

.fa-envelope {
  color: rgb(180, 180, 180);
}`, "",{"version":3,"sources":["webpack://./src/app/components/notifications/notifications.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,SAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ","sourcesContent":[".badge {\n    position: relative;\n    top: -7px;\n    left: -20px;\n    border: 1px solid;\n    border-radius: 50%;\n}\n\n.fa-envelope {\n    color: rgb(180, 180, 180);\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
