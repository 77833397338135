// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .modal-central-notificacoes .modal-dialog {
  width: 60%;
  min-width: 360px;
  font-size: 12px;
}

.nav-link {
  font-weight: 600;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.fa-trash {
  color: rgb(250, 100, 100);
}

hr {
  margin: 0px;
}

pre {
  margin: 1px;
  white-space: pre-wrap; /* Since CSS 2.1 */
  white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
  white-space: -pre-wrap; /* Opera 4-6 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* Internet Explorer 5.5+ */
  font-family: "Open Sans", "sans-serif";
}

.form-check-label {
  font-size: small;
}

.select-all-options {
  padding-top: 5px;
  margin-left: 10px;
}

.notify-list-checkbox {
  width: 10px;
}

.global-checkbox {
  width: 12px;
  margin-top: 0.075rem;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-central-notificacoes/modal-central-notificacoes.component.scss"],"names":[],"mappings":"AAAA;EACI,UAAA;EACA,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,gBAAA;EACA,oBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,qBAAA,EAAA,kBAAA;EACA,0BAAA,EAAA,wBAAA;EACA,sBAAA,EAAA,cAAA;EACA,wBAAA,EAAA,YAAA;EACA,qBAAA,EAAA,2BAAA;EACA,sCAAA;AACJ;;AAEA;EACI,gBAAA;AACJ;;AAEA;EACI,gBAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,WAAA;EACA,oBAAA;AACJ","sourcesContent":["::ng-deep .modal-central-notificacoes .modal-dialog {\n    width: 60%;\n    min-width: 360px;\n    font-size: 12px;\n}\n\n.nav-link {\n    font-weight: 600;\n    padding-left: 0.5rem;\n    padding-right: 0.5rem;\n}\n\n.fa-trash {\n    color: rgb(250, 100, 100);\n}\n\nhr {\n    margin: 0px;\n}\n\npre {\n    margin: 1px;\n    white-space: pre-wrap;       /* Since CSS 2.1 */\n    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */\n    white-space: -pre-wrap;      /* Opera 4-6 */\n    white-space: -o-pre-wrap;    /* Opera 7 */\n    word-wrap: break-word;       /* Internet Explorer 5.5+ */\n    font-family: 'Open Sans', 'sans-serif';\n}\n\n.form-check-label {\n    font-size: small;\n}\n\n.select-all-options {\n    padding-top: 5px;\n    margin-left: 10px; \n}\n\n.notify-list-checkbox {\n    width: 10px;\n}\n\n.global-checkbox {\n    width: 12px;\n    margin-top: 0.075rem;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
