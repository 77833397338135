// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 1px #ffffff;
  box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
}

.side-logo {
  display: block;
  margin-top: 0.4rem;
  margin-left: 0.4rem;
  width: 20%;
  height: 20%;
}

@media only screen and (min-width: 768px) {
  .logo-height {
    max-height: inherit !important;
    height: 6rem !important;
  }
  .navbar-vertical {
    box-shadow: none !important;
  }
  .marginttopneg-40 {
    margin-top: -40px;
  }
  .nav-text {
    display: none;
    font-size: 13px;
  }
  .d-none-desktop {
    display: none !important;
  }
  .d-block-desktop {
    display: block !important;
  }
}
@media only screen and (max-width: 767px) {
  .logo-height {
    max-height: inherit !important;
    height: 3rem !important;
  }
  .d-none-mobile {
    display: none !important;
  }
  .d-block-mobile {
    display: block !important;
  }
}
.investidor-name {
  font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/app/components/sidebar/sidebar.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,2CAAA;EACA,8CAAA;EACA,mBAAA;EACA,uDAAA;EACA,+DAAA;EACA,4DAAA;AACJ;;AAEA;EACI,cAAA;EACA,kBAAA;EACA,mBAAA;EACA,UAAA;EACA,WAAA;AACJ;;AAEA;EACI;IACI,8BAAA;IACA,uBAAA;EACN;EAEE;IACI,2BAAA;EAAN;EAGE;IACI,iBAAA;EADN;EAIE;IACI,aAAA;IACA,eAAA;EAFN;EAKE;IACI,wBAAA;EAHN;EAME;IACI,yBAAA;EAJN;AACF;AAOA;EACI;IACI,8BAAA;IACA,uBAAA;EALN;EAQE;IACI,wBAAA;EANN;EASE;IACI,yBAAA;EAPN;AACF;AAUA;EACI,gBAAA;AARJ","sourcesContent":[".logo {\n    border-radius: 200px 200px 200px 200px;\n    -moz-border-radius: 200px 200px 200px 200px;\n    -webkit-border-radius: 200px 200px 200px 200px;\n    border: 1px #ffffff;\n    box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);\n    -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);\n    -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);\n}\n\n.side-logo {\n    display: block;\n    margin-top: 0.4rem;\n    margin-left: 0.4rem;\n    width: 20%;\n    height: 20%;\n}\n\n@media only screen and (min-width: 768px) {\n    .logo-height {\n        max-height: inherit !important;\n        height: 6rem !important;\n    }\n\n    .navbar-vertical {\n        box-shadow: none !important;\n    }\n\n    .marginttopneg-40 {\n        margin-top: -40px;\n    }\n\n    .nav-text {\n        display: none;\n        font-size: 13px;\n    }\n\n    .d-none-desktop {\n        display: none !important;\n    }\n\n    .d-block-desktop {\n        display: block !important;\n    }\n}\n\n@media only screen and (max-width: 767px) {\n    .logo-height {\n        max-height: inherit !important;\n        height: 3rem !important;\n    }\n\n    .d-none-mobile {\n        display: none !important;\n    }\n\n    .d-block-mobile {\n        display: block !important;\n    }\n}\n\n.investidor-name {\n    font-size: small;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
