/* src/app/components/modal-central-notificacoes/modal-central-notificacoes.component.scss */
::ng-deep .modal-central-notificacoes .modal-dialog {
  width: 60%;
  min-width: 360px;
  font-size: 12px;
}
.nav-link {
  font-weight: 600;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.fa-trash {
  color: rgb(250, 100, 100);
}
hr {
  margin: 0px;
}
pre {
  margin: 1px;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
  font-family: "Open Sans", "sans-serif";
}
.form-check-label {
  font-size: small;
}
.select-all-options {
  padding-top: 5px;
  margin-left: 10px;
}
.notify-list-checkbox {
  width: 10px;
}
.global-checkbox {
  width: 12px;
  margin-top: 0.075rem;
}
/*# sourceMappingURL=modal-central-notificacoes.component.css.map */
