/* src/app/layouts/auth-layout/auth-layout.component.scss */
.logo {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 1px #ffffff;
  box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
}
@media only screen and (min-width: 768px) {
  .logo-height {
    max-height: inherit !important;
    height: 6rem !important;
  }
}
@media only screen and (max-width: 767px) {
  .logo-height {
    max-height: inherit !important;
    height: 3rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}
/*# sourceMappingURL=auth-layout.component.css.map */
