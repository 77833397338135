// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `td {
  font-size: small;
}

input {
  width: 100%;
}

.unmarked {
  color: rgb(230, 230, 230);
}

::ng-deep .modal-multi-investidor .modal-dialog {
  max-width: 60%;
  min-width: 360px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-multi-investidor/modal-multi-investidor.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,WAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,cAAA;EACA,gBAAA;AACJ","sourcesContent":["td {\n    font-size: small;\n}\n\ninput {\n    width: 100%;\n}\n\n.unmarked {\n    color: rgb(230, 230, 230);\n}\n\n::ng-deep .modal-multi-investidor .modal-dialog {\n    max-width: 60%;\n    min-width: 360px;\n }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
