// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.image-profile {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 1px #ffffff;
  box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
}

.investidor-name {
  font-size: small;
}`, "",{"version":3,"sources":["webpack://./src/app/components/navbar/navbar.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,2CAAA;EACA,8CAAA;EACA,mBAAA;EACA,uDAAA;EACA,+DAAA;EACA,4DAAA;AACJ;;AAEA;EACI,gBAAA;AACJ","sourcesContent":[".image-profile {\n    border-radius: 200px 200px 200px 200px;\n    -moz-border-radius: 200px 200px 200px 200px;\n    -webkit-border-radius: 200px 200px 200px 200px;\n    border: 1px #ffffff;\n    box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);\n    -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);\n    -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);\n}\n\n.investidor-name {\n    font-size: small;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
