import { Component, Input, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { DynamicStyleService } from 'src/app/services/dynamic-style.service';
import { StockService } from 'src/app/services/stock.service';
import DateUtil from 'src/app/util/date-util';
import StaticParams from 'src/app/util/static-params';

@Component({
    selector: 'app-ativo-grafico',
    templateUrl: './ativo-grafico.component.html',
    styleUrls: ['./ativo-grafico.component.scss'],
    standalone: false
})
export class AtivoGraficoComponent implements OnInit {

  @Input() portfolio: any = {}
  @Input() stockinfo: any = {}

  public cotacaoAjustada = true
  public periodoSelecionado = 12
  public historical = {}
  public historical_ajustado = {}

  public price = 0
  public change = 0
  public changepct = 0

  constructor(
    private _stockService: StockService,
    public dynamicCss: DynamicStyleService,
  ) { }

  ngOnInit(): void {
    this.historical = this.stockinfo && this.stockinfo.historical ? this.stockinfo.historical : {}
    this.historical_ajustado = this.stockinfo && this.stockinfo.historical_ajustado ? this.stockinfo.historical_ajustado : {}
    this.loadChart()
  }

  loadChart() {
    const historicalChart = JSON.parse(JSON.stringify(StaticParams.lineChart))
    historicalChart.subtitle.text = null
    let cotacao = []
    let cotacao_ajustado = []

    let historicalDates = this.cotacaoAjustada && Object.keys(this.historical_ajustado).length ?
      Object.keys(this.historical_ajustado) : Object.keys(this.historical)

    for (let i in historicalDates) {
      let dateBr: any = DateUtil.usStrDatetoBrStrDate(historicalDates[i]).split('/')
      dateBr[2] = dateBr[2].substring(2, 4)
      dateBr = dateBr.join('/')

      historicalChart.xAxis.categories.push(dateBr.substring(0, 10))

      let price = this.historical[historicalDates[i]] ?
        parseFloat(this.historical[historicalDates[i]].toFixed(2)) : null

      let price_ajustado = this.historical_ajustado[historicalDates[i]] ?
        parseFloat(this.historical_ajustado[historicalDates[i]].toFixed(2)) : price

      cotacao.push(price)
      cotacao_ajustado.push(price_ajustado)
    }

    historicalChart.series = [
      {
        name: this.cotacaoAjustada ? 'HISTÓRICO AJUSTADO' : 'HISTÓRICO SEM AJUSTE',
        data: this.cotacaoAjustada ? cotacao_ajustado : cotacao
      },
    ]

    Highcharts.setOptions({
      lang: {
        decimalPoint: ',',
        thousandsSep: '.'
      },
      accessibility: {
        enabled: false
      }
    });

    let fistPrice = cotacao_ajustado[0] || 0
    this.price = cotacao_ajustado[cotacao_ajustado.length - 1]
    this.change = this.price - fistPrice
    this.changepct = ((this.price / fistPrice) - 1) * 100

    Highcharts.chart('ativo-historical-chart', historicalChart);
  }

  toggleCotacaoAjustado() {
    this.cotacaoAjustada = !this.cotacaoAjustada
    this.loadChart()
  }

  getPeriodHistorical(period) {
    this.periodoSelecionado = period

    let ativo = this.portfolio.market_cod || this.portfolio.ativo

    this._stockService.getIndividualStock(ativo, period).subscribe({
      next: async (response) => {
        this.historical = response[ativo] ? response[ativo].historical : {}
        this.historical_ajustado = response[ativo] ? response[ativo].historical_ajustado : {}
        this.loadChart()
      },
      error: (err) => { }
    })
  }



}
