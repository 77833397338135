import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { BehaviorSubject } from 'rxjs';
import FilterUtil from 'src/app/util/filter-util';
import StaticParams from 'src/app/util/static-params';
import { AlocacaoService } from './alocacao.service';
import { BaseApiService } from './base-api.service';
import { CacheService } from './cache.service';
import { NotificationService } from './notification.service';
import { StockService } from './stock.service';
import { WalletService } from './wallet.service';

@Injectable({
  providedIn: 'root'
})
export class SummaryService extends BaseApiService {
  public behaviorSummary: BehaviorSubject<any>;
  public behaviorWallet: BehaviorSubject<any>;
  public filterOptions = {}
  private wallet: any;

  constructor(
    protected baseApi: HttpClient,
    protected router: Router,
    protected toastr: ToastrService,
    private _walletService: WalletService,
    private _alocacaoService: AlocacaoService,
    private _stockService: StockService,
    private _notificationService: NotificationService,
    private spinnerService: NgxSpinnerService,
    private _cacheService: CacheService,
  ) {
    super(baseApi, router, toastr);
    this.behaviorWallet = new BehaviorSubject(null);
    this.behaviorSummary = new BehaviorSubject(null);
  }

  getSummary(filters: string = '') {
    filters = 'start_notifications_date=' + new Date(new Date().setMonth(new Date().getMonth() - 6)).toISOString().split('T')[0] + '&' + filters
    return this.get(this.urlBaseApi + `/resumo/wid/${this.walletId}?${filters}`, false)
  }

  updateSummaryBehavior(isTimerRefresh = null) {
    if (!isTimerRefresh) {
      this._cacheService.clearCacheOnOperationsChange()
    }

    let timerAviso: number = 5
    let summaryLoaded: boolean = false
    let summaryToastId: number = null

    let intervalLoading = window.setInterval(() => {
      if (timerAviso > 0) {
        timerAviso--
      } else if (summaryLoaded) {
        window.clearInterval(intervalLoading)
      } else {
        summaryToastId = this.toastr.info("Calculando dados...", "Aguarde um momento!", {
          disableTimeOut: true,
          tapToDismiss: false
        }).toastId

        window.clearInterval(intervalLoading)
      }
    }, 1000)

    this.getSummary().subscribe({
      next: async (summaryResponse) => {
        summaryLoaded = true
        timerAviso = 5
        this.successGetSummary(summaryToastId, summaryResponse)
      },
      error: (err) => {
        if (err.name == "TimeoutError" || err.code == 524 || err.code == 0) {
          this._walletService.isFullPortfolioComplete().subscribe({
            next: (res) => {
              this.getSummary().subscribe({
                next: async (summaryResponse) => {
                  summaryLoaded = true
                  timerAviso = 5
                  this.successGetSummary(summaryToastId, summaryResponse)
                },
                error: (err) => {
                  this.errorGetSummary(summaryToastId)
                }
              })
            },
            error: (err) => {
              this.toastr.error("Os dados do seu portfolio não foram carregados corretamente. Por favor, tente novamente ou entre em contato com nosso suporte!")
              this.errorGetSummary(summaryToastId)
            }
          })
        } else {
          this.errorGetSummary(summaryToastId)
        }
      }
    })
  }

  private successGetSummary(summaryToastId: number, summaryResponse: any) {
    if (summaryToastId) {
      this.toastr.remove(summaryToastId)
    }

    this.wallet = summaryResponse.wallet
    this._cacheService.setCache({ 'summaryData': summaryResponse.summary })
    this._cacheService.setCache({ 'systemNotifications': summaryResponse.notifications })
    this._alocacaoService.setAlocacaoWalletCache(summaryResponse.multiwallet)
    this.getStockDataAndTriggerBehavior(summaryResponse)
  }

  private errorGetSummary(summaryToastId: number) {
    this.spinnerService.hide()
    if (summaryToastId) {
      this.toastr.remove(summaryToastId)
    }
  }

  getEventsOnServer(calcWallet = true) {
    let hashlist = [
      'plano', 'promocao', 'versao', 'alerta', 'aviso', 'proventos', 'eventos', 'subscricao',
      'noticias', 'fatosrelevantes', 'comunicadosavisos', 'informesrelatorios', 'atas'
    ]

    hashlist = hashlist
      .concat(this._notificationService.getVersionMessages().hashList)
      .concat(this._notificationService.getAtivosMessages().hashList)
      .concat(this._notificationService.getSystemMessages().hashList)

    this._notificationService.searchEventsAndNewsByList(hashlist).subscribe({
      next: (eventsNews) => {
        let eventsNewStatus = {}
        for (let i in eventsNews) {
          eventsNewStatus[eventsNews[i].hash] = {
            hash: eventsNews[i].hash,
            done: eventsNews[i].done,
            delete: eventsNews[i].delete
          }
        }

        this._cacheService.setCache({ 'eventsNewStatus': eventsNewStatus })
        this._notificationService.calcUnreadAndNotifications()

        if (calcWallet) {
          this.calcwallet(this.wallet)
        }
      },
      error: (err) => { }
    })
  }

  removeOutrosAtivos(marketCodList) {
    let result = []
    for (let i in marketCodList) {
      if (marketCodList[i].indexOf('OUTROS:') < 0 && marketCodList[i].indexOf('!ERROR') < 0) {
        result.push(marketCodList[i])
      }
    }
    return result
  }

  private getStockDataAndTriggerBehavior(summaryResponse: any) {
    try {
      let marketCodList: any = FilterUtil.distincValuesOnObjectList(summaryResponse.wallet, 'market_cod')
      marketCodList = this.removeOutrosAtivos(marketCodList)

      let hasNewStock = false
      let stockData = JSON.parse(localStorage.getItem('stockData')) || {}
      let currencyIndexData = JSON.parse(localStorage.getItem('currencyIndexData')) || {}
      let stockCurrencys = Object.keys(StaticParams.currencySymbols)
      let currencys_indexes = []

      for (let i in stockCurrencys) {
        if (stockCurrencys[i] != 'BRL') {
          currencys_indexes.push(stockCurrencys[i] + 'BRL')
        }
      }

      currencys_indexes = currencys_indexes.concat(StaticParams.indexSymbols)

      marketCodList.forEach(el => {
        if (!stockData[el]) { hasNewStock = true }
      });

      const lastUpdateStockPrice = new Date(summaryResponse.price_update_time)
      let isToUpdateCurrencysIndexes: boolean = false
      let isToUpdateStockData: boolean = true

      if (currencyIndexData['IBOVESPA']) {
        const timeUpdatedIndex = currencyIndexData['IBOVESPA'].response_time
        if (lastUpdateStockPrice.toISOString() > timeUpdatedIndex) {
          isToUpdateCurrencysIndexes = true
        }
      } else {
        isToUpdateCurrencysIndexes = true
      }

      if (!hasNewStock && stockData[marketCodList[0]]) {
        const dateNow = new Date().toISOString()
        const localPriceTime = stockData[marketCodList[0]].response_time

        let localTime = localPriceTime.split('T')
        let time = localTime[1].split(':')
        let hour = (parseInt(time[0]) + 4).toString()
        if (hour.length == 1) {
          hour = '0' + hour
        }
        const updateTime = `${localTime[0]}T${[hour, time[1], time[2]].join(':')}`

        if (dateNow < updateTime) {
          isToUpdateStockData = false
        }
      }

      if (isToUpdateCurrencysIndexes) {
        this.updateCurrencyIndexData(currencys_indexes, summaryResponse, marketCodList, isToUpdateStockData)
      } else if (isToUpdateStockData) {
        this.triggerWhenStockData(summaryResponse, marketCodList)
      } else {
        this.triggerWhenNoStockData(summaryResponse)
      }
    } catch (e) {
      this.behaviorSummary.next(summaryResponse)
    }
  }

  updateCurrencyIndexData(currencys_indexes, summaryResponse, marketCodList, isToUpdateStockData) {
    this._stockService.getStockData(currencys_indexes).subscribe({
      next: async (currencysIndexesData) => {
        localStorage.setItem('currencyIndexData', JSON.stringify(currencysIndexesData))

        if (isToUpdateStockData) {
          this.triggerWhenStockData(summaryResponse, marketCodList)
        } else {
          this.triggerWhenNoStockData(summaryResponse)
        }
      },
      error: (err) => {
        localStorage.setItem('currencyIndexData', JSON.stringify({}))
        if (isToUpdateStockData) {
          this.triggerWhenStockData(summaryResponse, marketCodList)
        } else {
          this.triggerWhenNoStockData(summaryResponse)
        }
      }
    })
  }

  triggerWhenNoStockData(summaryResponse) {
    if (!this._cacheService.getCache('eventsNewStatus')) {
      this.getEventsOnServer()
    } else {
      this._notificationService.calcUnreadAndNotifications()
      this.calcwallet(summaryResponse.wallet)
    }

    this.behaviorSummary.next(summaryResponse)
  }

  triggerWhenStockData(summaryResponse, marketCodList) {
    this._stockService.getStockData(marketCodList, false).subscribe({
      next: async (stockData) => {
        localStorage.setItem('stockData', JSON.stringify(stockData))
        this.getEventsOnServer();
        this.behaviorSummary.next(summaryResponse)
      },
      error: (err) => {
        localStorage.setItem('stockData', JSON.stringify({}))
        this.getEventsOnServer();
        this.calcwallet(summaryResponse.wallet);
        this.behaviorSummary.next(summaryResponse)
      }
    })
  }

  calcwallet(walletObject) {
    this.behaviorWallet.next(this._walletService.calculateFullWallet(walletObject));
  }
}