<footer class="footer pt-2 pb-2" [ngClass]="isDarkMode ? 'bg-vs-dark4' : '' ">
  <div class="align-items-center justify-content-center">

    <!-- Social Midia -->
    <div class="">
      <div class="row ml-0 mr-0 align-items-center justify-content-left">
        <a class="nav-link" href="https://bit.ly/dlombellodiscord" target="_blank">
          <i class="fab fa-discord"></i>
        </a>
        <a class="nav-link" href="https://www.youtube.com/c/dlombelloplanilhas" target="_blank">
          <i class="fab fa-youtube"></i>
        </a>
        <a class="nav-link" href="https://www.instagram.com/dlombelloplanilhas" target="_blank">
          <i class="fab fa-instagram"></i>
        </a>
        <a class="nav-link" href="https://www.facebook.com/groups/dlombelloplanilhas/" target="_blank">
          <i class="fab fa-facebook"></i>
        </a>
        <a class="nav-link" href="https://t.me/+RsdRcxWP6BU5JXo3" target="_blank">
          <i class="fab fa-telegram"></i>
        </a>
      </div>
    </div>

    <div class="text-center small font-weight-bold" (click)='showModalVersions()'>
      <span role="button"> <u>{{ versaoAtual }}</u> </span>
    </div>

    <div class="pt-0">
      <div class="text-center">
        &copy; {{ date | date: "yyyy" }} <a href="https://dlombelloplanilhas.com/" class="font-weight-bold ml-1"
          target="_blank">dlombelloplanilhas</a>
      </div>
      <div class="copyright text-center small">
        All Rights Reserved.
      </div>
    </div>

  </div>
</footer>