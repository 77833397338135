// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `::ng-deep .modal-table .modal-dialog {
  max-width: 65rem;
  font-size: 12px;
}

.unmarked {
  color: rgb(230, 230, 230);
}

td {
  white-space: pre-wrap;
  word-wrap: break-word;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-table/modal-table.component.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEE;EACE,yBAAA;AACJ;;AAEE;EACE,qBAAA;EACA,qBAAA;AACJ","sourcesContent":["::ng-deep .modal-table .modal-dialog {\n    max-width: 65rem;\n    font-size: 12px;\n  }\n  \n  .unmarked {\n    color: rgb(230, 230, 230);\n  }\n  \n  td {\n    white-space: pre-wrap;\n    word-wrap: break-word;\n  }  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
