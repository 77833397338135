// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-title {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}

::ng-deep .modal-simple-operation-dialog .modal-dialog {
  max-width: 30rem;
  font-size: 13px;
}

#operation-categoria {
  margin: auto;
  width: 335px;
}

input {
  text-align: center;
  padding: 3px;
  border-radius: 4px;
  height: 35px;
  width: 172px;
}

.select-option {
  width: 172px;
  margin: auto;
}

::ng-deep .ng-has-value .ng-value-container {
  display: inline-block !important;
}

.checkbox-text {
  font-size: 13px;
  font-weight: 500;
  color: #fff;
}

.link-saldo {
  text-decoration: underline;
  color: #50bace;
}

.corretora-upper {
  text-transform: uppercase;
}

.operation-edit-date {
  width: 142px;
}

.op-date-calendar {
  height: 32px;
  margin-top: 2px;
}

.rf-info-header {
  padding-top: 10px;
}

.rf-info-list {
  text-align: left;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-simple-operation/modal-simple-operation.component.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,gBAAA;EACA,eAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,kBAAA;EACA,YAAA;EACA,kBAAA;EACA,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,YAAA;AACJ;;AAEA;EACI,gCAAA;AACJ;;AAEA;EACI,eAAA;EACA,gBAAA;EACA,WAAA;AACJ;;AAEA;EACI,0BAAA;EACA,cAAA;AACJ;;AAEA;EACI,yBAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,YAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;AACJ;;AACA;EACI,gBAAA;AAEJ","sourcesContent":[".modal-title {\n    font-size: 1rem;\n    font-weight: 600;\n    color: #fff;\n}\n\n::ng-deep .modal-simple-operation-dialog .modal-dialog {\n    max-width: 30rem;\n    font-size: 13px;\n}\n\n#operation-categoria {\n    margin: auto;\n    width: 335px;\n}\n\ninput {\n    text-align: center;\n    padding: 3px;\n    border-radius: 4px;\n    height: 35px;\n    width: 172px;\n}\n\n.select-option {\n    width: 172px;\n    margin: auto;\n}\n\n::ng-deep .ng-has-value .ng-value-container {\n    display: inline-block !important;\n}\n\n.checkbox-text {\n    font-size: 13px;\n    font-weight: 500;\n    color: #fff;\n}\n\n.link-saldo {\n    text-decoration: underline;\n    color: #50bace;\n}\n\n.corretora-upper {\n    text-transform: uppercase;\n}\n\n.operation-edit-date {\n    width: 142px;\n}\n\n.op-date-calendar {\n    height: 32px;\n    margin-top: 2px;\n}\n\n.rf-info-header{\n    padding-top: 10px;\n}\n.rf-info-list{\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
