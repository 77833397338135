<div class="ml-3 cursor-pointer" (click)='openModalProfile()'>
    <i class="fas fa-user-circle"></i>
    <span class="ml-2">Minha Conta</span>
</div>

<div class="dropdown-divider"></div>

<div class="ml-3 cursor-pointer" (click)='openModalmultiInvestidor()'>
    <i class="fas fa-users"></i>
    <span class="ml-2">Multi Investidor</span>
</div>

<div class="dropdown-divider"></div>

<div class="ml-3 mt-2 cursor-pointer" (click)='openModalTermosUso()'>
    <i class="ni ni-single-copy-04"></i>
    <span class="ml-2">Termos de uso</span>
</div>

<div *ngIf="windowWidth >= 767">
    <div class="dropdown-divider"></div>

    <div class="ml-3 cursor-pointer" (click)='restartTour()'>
        <i class="fas fa-bus"></i>
        <span class="ml-2">Tutorial</span>
    </div>
</div>

<div class="dropdown-divider"></div>

<div class="ml-3 cursor-pointer" (click)='logout()'>
    <i class="ni ni-user-run"></i>
    <span class="ml-2">Sair</span>
</div>