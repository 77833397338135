import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ViewOptionsService } from 'src/app/services/view-options.service';
import { ModalTableComponent, modalTableOptions } from '../modal-table/modal-table.component';
import StaticParams from 'src/app/util/static-params';
import { NotificationService } from 'src/app/services/notification.service';
import FilterUtil from 'src/app/util/filter-util';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: false
})
export class FooterComponent implements OnInit {
  public date: Date = new Date();
  public versaoAtual = 'v1.0.00'
  public isDarkMode = false
  public listaDeVersoes = StaticParams.versionNotifications

  constructor(
    private _modalService: NgbModal,
    private _viewOptionsService: ViewOptionsService,
    private _notificationService: NotificationService,
  ) { }

  ngOnInit() {
    this._viewOptionsService.behaviorDarkMode.subscribe(darkMode => this.isDarkMode = darkMode);
    this._notificationService.behaviorNotifications.subscribe({
      next: (result) => {
        this.listaDeVersoes = FilterUtil.lodash().orderBy(result?.versionAllNotifications || [], ['date'], ['desc'])
        this.versaoAtual = this.listaDeVersoes[0]?.titulo?.split('|')[0].trim() || this.versaoAtual
      }
    })
  }

  showModalVersions() {
    this.listaDeVersoes.forEach((o) => {
      o.titulo = o.titulo?.split('|')[0].trim() || o.titulo
    })
    const modalRef = this._modalService.open(ModalTableComponent, modalTableOptions);
    modalRef.componentInstance.modalProps = {
      data: this.listaDeVersoes,
      title: `HISTÓRICO DE VERSÕES`,
      colKeys: 'date,titulo,mensagem'.split(','),
      colValues: 'Data,Versão,Atualizações'.split(','),
    }
  }

}
