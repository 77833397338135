// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `th {
  font-size: 0.55rem;
  padding: 0.2rem;
}

td {
  font-size: 0.6rem;
  padding: 0.2rem;
  min-width: 60px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modal-fundamentos/ativo-info/ativo-info.component.scss"],"names":[],"mappings":"AAAA;EACI,kBAAA;EACA,eAAA;AACJ;;AAEA;EACI,iBAAA;EACA,eAAA;EACA,eAAA;AACJ","sourcesContent":["th {\n    font-size: 0.55rem;\n    padding: 0.2rem;\n}\n\ntd {\n    font-size: 0.6rem;\n    padding: 0.2rem;\n    min-width: 60px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
