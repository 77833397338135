export const commonEnv = {
  production: false,
  urlBaseApi: 'https://users-test.dlombelloplanilhas.com',
  firebase: {
    apiKey: "AIzaSyDWC6u3ceR2AC9t5dTX1ehcX0XI6qfTwRY",
    authDomain: "dlombelloplanilhas-web-dev.firebaseapp.com",
    projectId: "dlombelloplanilhas-web-dev",
    storageBucket: "dlombelloplanilhas-web-dev.appspot.com",
    messagingSenderId: "50017197061",
    appId: "1:50017197061:web:a4d37ef13e8f3261e8d06c",
    measurementId: "G-LMZ3ZDF7CK"
  }
};
