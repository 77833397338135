// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.logo {
  border-radius: 200px 200px 200px 200px;
  -moz-border-radius: 200px 200px 200px 200px;
  -webkit-border-radius: 200px 200px 200px 200px;
  border: 1px #ffffff;
  box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -webkit-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
  -moz-box-shadow: -1px 2px 24px 3px rgba(255, 255, 255, 0.57);
}

@media only screen and (min-width: 768px) {
  .logo-height {
    max-height: inherit !important;
    height: 6rem !important;
  }
}
@media only screen and (max-width: 767px) {
  .logo-height {
    max-height: inherit !important;
    height: 3rem !important;
    margin-left: auto !important;
    margin-right: auto !important;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/auth-layout/auth-layout.component.scss"],"names":[],"mappings":"AAAA;EACI,sCAAA;EACA,2CAAA;EACA,8CAAA;EACA,mBAAA;EACA,uDAAA;EACA,+DAAA;EACA,4DAAA;AACJ;;AAGA;EACI;IACI,8BAAA;IACA,uBAAA;EAAN;AACF;AAGA;EACI;IACI,8BAAA;IACA,uBAAA;IACA,4BAAA;IACA,6BAAA;EADN;AACF","sourcesContent":[".logo {\n    border-radius: 200px 200px 200px 200px;\n    -moz-border-radius: 200px 200px 200px 200px;\n    -webkit-border-radius: 200px 200px 200px 200px;\n    border: 1px #ffffff;\n    box-shadow: -1px 2px 24px 3px rgba(255,255,255,0.57);\n    -webkit-box-shadow: -1px 2px 24px 3px rgba(255,255,255,0.57);\n    -moz-box-shadow: -1px 2px 24px 3px rgba(255,255,255,0.57);\n}\n\n\n@media only screen and (min-width: 768px) {\n    .logo-height {\n        max-height: inherit !important;\n        height: 6rem !important;\n    }\n}\n\n@media only screen and (max-width: 767px) {\n    .logo-height {\n        max-height: inherit !important;\n        height: 3rem !important;\n        margin-left: auto !important;\n        margin-right: auto !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
